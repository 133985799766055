import { useState } from 'react'
import { Alert, Badge, Button, Form, Modal, Table } from 'react-bootstrap'
import './AddListings.scss'
import axios from "axios";
import Session from "supertokens-auth-react/recipe/session";
import Swal from 'sweetalert2';
Session.addAxiosInterceptors(axios);

export default function AddListings({ownedTrees, getTrees}) {
    const [addList, setAddList] = useState([])
    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(true);
    }

    const handleChange = (e, id) => {
        if (e.target.checked) {
            let getItem = ownedTrees.findIndex(test => test.tree_id === id)
            handleAdd(ownedTrees[getItem])
        }
        else {
            handleRemove(id)
        }

    }
    const handleAdd = (value) => {
        setAddList([...addList, value])
    }
    const handleRemove = (value) => {
        let copy = addList
        copy.splice(copy.findIndex(e => e.tree_id === value), 1)
        setAddList([...addList])
    }
    const handleConfirm = () => {
        axios.post(process.env.REACT_APP_API + '/user/trees/listing/add', addList).then(res=>{
            res.data === 'success' ? 
            Swal.fire({icon: 'success', text: 'Updated', timer: 2000, showConfirmButton: false}).then(()=>{
                getTrees()
                setShow(false)
            })
            : 
            Swal.fire({icon: 'error', text: res.data, timer: 2000, showConfirmButton: false}).then(()=>{
                setShow(false)
            })
        })
    }
    
    return (
        <div className="add-listings">
                        <Alert variant="primary">Add Trees to the marketplace:</Alert>
            <Modal size="lg" show={show}  onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Please Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>The following trees that you own will be listed on the marketplace:</strong>
                    <hr/>
                    {addList.map((item, index) => (
                    <div>
                        <span>TREE ID: <strong> {item.tree_id}</strong></span>
                        <p style={{marginLeft: '1em'}}>Tree Type: {item.tree_type}</p>
                        <p style={{marginLeft: '1em'}}>Date Planted: {item.date_planted} (Age: ~ {Math.floor(item.months /12).toFixed(2)} Years)</p>
                        
                        <p style={{marginLeft: '1em'}}>Current List Price: <Badge bg="secondary" style={{fontSize: '1em'}} >${(+item.current_value).toFixed(2)}</Badge> </p>
                    </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShow(false)}>Cancel</Button>
                    <Button variant="primary" onClick={()=>handleConfirm()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
            <Alert variant="secondary">
                <strong>
                    Select Trees by ID to add to the marketplace:
                </strong>
                <hr />

            </Alert>

            <Table responsive striped bordered hover >
                <thead>
                    <tr>
                        <th>Select by Tree ID</th>
                        <th>Type</th>
                        <th>Date Planted</th>
                        <th>Tree Age</th>
                        <th>Est. Value</th>
                    </tr>
                </thead>
                <tbody>

                    {ownedTrees.map((tree, index) => (
                        <tr key={index + 'check'}>
                            <td>
                                <input type="checkbox" onChange={e => handleChange(e, tree.tree_id)} />
                                <label style={{ marginLeft: '1em' }}>{tree.tree_id}</label>
                            </td>
                            <td>{tree.tree_type}</td>
                            <td>{tree.date_planted}</td>
                            <td>{Math.floor(tree.months /12).toFixed(0) + ' years '+ tree.months % 12 + ' month(s)'}</td>
                            <td style={{ display: 'flex', justifyContent: 'space-between' }}><div>$</div>{(+tree.current_value).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Alert variant="secondary">
                <strong>
                    Review your selections and press submit:
                </strong>
                <br />
                <hr />
                <Button onClick={() => setShow(true)} disabled={addList.length > 0 ? false : true}>Submit</Button>

            </Alert>
        </div>
    )
}