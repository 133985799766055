import { Badge, Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import '../Marketplace.scss'
export default function VendorCard({ vendor, list, id, you }) {
    const history = useHistory()
    return (
        <Card border="success" style={{ width: '300px' }}>
            <Card.Header ><img id="market-icon" src="/market.svg" width="30px" /> Vendor {vendor}</Card.Header>
            <Card.Body bg="secondary" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                <ListGroup as="ol" >
                    {list.map((item, key) => (
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold" style={{ color: 'seagreen' }}>{item.tree_type}</div>
                                {Math.floor(item.months / 12).toFixed(0)} years {item.months % 12} months  old
                                <br />
                                <strong>${item.current_value}</strong>
                            </div>
                        </ListGroup.Item>
                    ))}

                </ListGroup>
                <div style={{ minWidth: '100%' }}>
                    <hr />
                    {you ? <Button disabled variant="success">This is you</Button> : <Button style={{ minWidth: '100%' }} variant="success" size="small" onClick={() => history.push(`/marketplace/${vendor}`)}>Visit Vendor</Button>}


                </div>
            </Card.Body>
        </Card>
    )
}