import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import './ClientWelcome.scss'
import treeSymbol from "../../../utilities/treeSymbols";
import { ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
Session.addAxiosInterceptors(axios);

export default function ClientWelcome({ userInfo}) {
    const history = useHistory()
    useEffect(() => {
    }, [])

    return (
        <div className="client-dash-content">

            <h2>Welcome to the forest where impacts are multipled.</h2>
            <hr />
            <br/>
            <ListGroup>
                <ListGroup.Item onClick={() => history.push('/marketplace')} action variant="light">
                    <img style={{ marginRight: '.5em' }} src="/forsale.svg" alt="market icon" width="30px" /> Marketplace
                </ListGroup.Item>
                <ListGroup.Item onClick={() => history.push('/my-trees')} action variant="light">
                    <img style={{ marginRight: '.5em' }} src="/forest.svg" alt="market icon" width="30px" /> My Trees
                </ListGroup.Item>
                <ListGroup.Item onClick={() => history.push('/my-documents')} action variant="light">
                    <img style={{ marginRight: '.5em' }} src="/my-docs.svg" alt="market icon" width="30px" /> My Documents
                </ListGroup.Item>
                <ListGroup.Item onClick={() => history.push('/faq')} action variant="light">
                    <img style={{ marginRight: '.5em' }} src="/faq.svg" alt="market icon" width="30px" /> FAQ
                </ListGroup.Item>
                <ListGroup.Item onClick={() => history.push('/profile')} action variant="light">
                    <img style={{ marginRight: '.5em' }} src="/profile.svg" alt="market icon" width="30px" /> Profile
                </ListGroup.Item>
             
            </ListGroup>
        </div>
    )
}