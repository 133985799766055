
import { useHistory } from 'react-router-dom'
import './AdminHome.scss'
import { Button, Card } from 'react-bootstrap'
import axios from 'axios'
export default function QuickReports() {
    const history = useHistory()

    const downloadInventoryOfSoldTrees = async () => {
        try {

            const response = await axios.get(process.env.REACT_APP_API + '/reports/owned-trees', {
                responseType: 'blob', // important
            });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'owned_trees.csv'); // Specify the file name

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);

        } catch (error) {
            console.error('Error downloading the file', error);
        }

    }

    const downloadZip = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API + '/backup/pdf', {
                responseType: 'blob', // important
            });

            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'documents.zip'); // Specify the file name

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    return (
        <>
            <h2>Quick Reports and Downloads</h2>
            <hr />
            <div className='admin-dash-row'>
                <Card style={{ maxWidth: '300px' }}>
                    <Card.Header><strong>Inventory of Sold Trees</strong></Card.Header>
                    <Card.Body>Download a spreadsheet containing a list of all trees that have been sold along with the contact information for each owner.</Card.Body>
                    <Card.Footer>
                        <Button onClick={() => downloadInventoryOfSoldTrees()}>Download</Button>
                    </Card.Footer>
                </Card>
                <Card style={{ maxWidth: '300px' }}>
                    <Card.Header><strong>Document Backup Zipped Folder</strong></Card.Header>
                    <Card.Body>Download a zipped folder containing all documents created by the system.</Card.Body>
                    <Card.Footer>
                        <Button onClick={() => downloadZip()}>Download</Button>
                    </Card.Footer>
                </Card>
            </div>

        </>

    )
}