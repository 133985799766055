import axios from "axios"
import { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Session from "supertokens-auth-react/recipe/session";
import './Owners.scss'
Session.addAxiosInterceptors(axios);

export default function Owners() {
    const [users, setUsers] = useState([])
    const history = useHistory()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = () => {
        axios.get(process.env.REACT_APP_API + '/admin/users').then(res => { setUsers(res.data) })
    }
    return (
        <div className="admin-dash-content">
            <h1>Tree Owners</h1>
            
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Owner ID</th>
                        <th>Last Name</th>
                        <th>First Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        {/* <th>BOS</th> */}
                    </tr>
                </thead>
                <tbody>
                    {users.length < 1 ?   <Spinner animation="border" variant="success" ></Spinner> :
                        users.map((item, index) => (
                            <tr onClick={()=>history.push('/owner/' + item.owner_id)} key={'user' + index}>
                                <td>{item.owner_id}</td>
                                <td>{item.last_name}</td>
                                <td>{item.first_name}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                {/* <td>{item.bill_of_sale === 'none' ? 'N/A' : <a href={item.bill_of_sale} target="_blank">View</a>}</td> */}
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}