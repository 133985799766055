import { Alert } from "react-bootstrap";


export default function ErrorPage() {
    return (
        <div className='onboarding-demo'>
            <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Document System</div>
            <div className="paper-container">
                <div className="paper">
                    <Alert variant="danger">
                        <Alert.Heading>
                            Sorry something isn't working as expected.
                        </Alert.Heading>
                        Please contact us at alain@azucenas.org
                    </Alert>
                </div>
            </div>
        </div>
    )
}