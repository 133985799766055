let marketData = [
    {vendor: '456',
     forSale: [
        {type: 'Honduras Mahogany', age: '4', count: 2, price: 525.00},
        {type: 'Cedar', age: '4', count: 2, price: 525.00},
        {type: 'Ciricote', age: '4', count: 1, price: 525.00}
    ],
    id: 'vendor_id'
    },
    {vendor: '826',
     forSale: [
        {type: 'Honduras Mahogany', age: '5', count: 2, price: 820.00},
        {type: 'Ciricote', age: '5', count: 4, price: 820.00}
    ],
    id: 'vendor_id'
    },
    {vendor: '423',
     forSale: [
        {type: 'Honduras Mahogany', age: '4', count: 1, price: 525.00},
        {type: 'Cedar', age: '4', count: 1, price: 525.00},
    
    ],
    id: 'vendor_id'
    },
]



export default marketData