import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import { Route, Switch } from "react-router-dom";

import AdminNav from "../adminDash/adminComponents/adminNav";

import QuickReports from "../adminDash/adminComponents/adminHome/quickReports";
import AdminMobileNav from "../adminDash/adminComponents/adminMobileNav";

Session.addAxiosInterceptors(axios);

export default function Admin2Dash({ logout }) {
    const [show, setShow] = useState(false)
    useEffect(() => {
    }, [])

    return (
        <div className="Admin-Dash">
            <div className="ClientMobileNav">
                <AdminMobileNav logout={logout} admin2={true} />
            </div>
            <AdminNav logout={logout} admin2={true} />
            <div className="Admin-Dash-Body">
                <Switch>
                    <Route exact path="/">
                        <div className='Admin-Dash'>

                            <div className="admin-dash-content">
                                <QuickReports />
                            </div>
                        </div>
                    </Route>
                </Switch>


            </div>
        </div>
    )
}