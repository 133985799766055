export default function convertDoctype(type, document) {
    switch (type) {
        case 'mgmt': return 'Management Agreement'
            break;
        case 'bos': return 'Bill of Sale'
            break;
        default:
            if (type === 'other' && document) {
                return document.document_name ?? '-'
            } else {
                return '-'
            }

    }
}