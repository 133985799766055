import { useState } from 'react'
import { Alert, Card, Form } from 'react-bootstrap'
import './LinkFormatter.scss'

export default function LinkFormatter() {
    const [link, setLink] = useState('')
    const [converted, setConverted] = useState('')

    const formatLink = (value) => {
        setLink(value)
        let ending = value.split('d/')[1]
        let trim = ending.split('/view?')[0]
        let downloadLink = 'https://drive.google.com/uc?export=download&id=' + trim
        setConverted(downloadLink)
    }

    return (
        <div className="Link-Formatter">
            <Card>
                <Card.Header>
                    Link Formatter
                </Card.Header>
                <Card.Body>
                    <Form.Group>
                        <Form.Label>Link</Form.Label>
                        <Form.Control type="text" value={link} onChange={e => formatLink(e.target.value)} />
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <Alert variant="success">

                        {converted}
                    </Alert>
                </Card.Footer>
            </Card>


        </div>
    )
}