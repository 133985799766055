import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import {Button, Spinner, Table, Badge, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";


export default function AllTrees() {

    const [trees, setTrees] = useState([])
    const [loading, setLoading] = useState(true)
    const [sector, setSector] = useState(0)
    const [search, setSearch] = useState('')
    const [line, setLine] = useState('')
    const [results, setResults] = useState([])
    const history = useHistory()

    useEffect(() => {
        getTrees()
    }, [])

    const getTrees = () => {
        axios.get(process.env.REACT_APP_API + '/admin/trees/all').then((res) => {
            setTrees(res.data)
            setLoading(false)
        })
    }

    const handleFilter = (items) => {

        let filterBySector = sector !== null ? items.filter(e => +e.sector === +sector) : items
        let adjustLine = 'L' + line
        let filterByLine = line !== '' ? filterBySector.filter(e => e.line == adjustLine) : filterBySector
        return filterByLine
    }
    const handleSetResults = async () => {
        let data = await handleFilter(trees)
        setResults(data)
    }

    return (
        loading ? <Spinner animation="border" variant="success" ></Spinner>
            :
            <div className="admin-dash-content">
                <Form.Label htmlFor="inline-radio-1">Select Sector</Form.Label>
                <div key={`inline-radio`} className="mb-3">
                    <Form.Check onClick={() => setSector(null)}
                        inline
                        label="All"
                        name="group1"
                        type="radio"
                        id={`inline-radio-1`}
                    />
                    <Form.Check onClick={() => setSector(1)}
                        inline
                        label="1"
                        name="group1"
                        type="radio"
                        id={`inline-radio-1`}
                    />
                    <Form.Check onClick={() => setSector(2)}
                        inline
                        label="2"
                        name="group1"
                        type="radio"
                        id={`inline-radio-2`}
                    />
                    <Form.Check onClick={() => setSector(3)}
                        inline
                        label="3"
                        name="group1"
                        type="radio"
                        id={`inline-radio-3`}
                    />
                          <Form.Check onClick={() => setSector(4)}
                        inline
                        label="4"
                        name="group1"
                        type="radio"
                        id={`inline-radio-3`}
                    />
                </div>
                <Form.Label>Enter Line (1-167)</Form.Label>
                <Form.Control onChange={e => setLine(e.target.value)} type="number" value={line} />
                <br />
                <Button variant="success" onClick={() => handleSetResults()}>Search</Button>
                <br/>
                <hr/>
                <p>{results.length}  Results</p>
                {
                    results.length < 1 ? <p>Search for trees using the filters above.</p> :
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Type</th>
                                    <th>Owner</th>
                                    <th>Date Planted</th>
                                    <th>Current Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map((tree, index) => (
                                    <tr>
                                        <td>{tree.tree_id}</td>
                                        <td>{tree.tree_type}</td>
                                        <td>{tree.owner_id ? <Badge onClick={()=>history.push('/owner/' + tree.owner_id)} variant="success">{tree.first_name} {tree.last_name}</Badge> : tree.reserved_for ? <Badge bg="warning" text="dark" onClick={()=>history.push('/document/'+tree.document + '/bos')}>Reserved: {tree.reserved_for}</Badge> : 'No Owner'}</td>
                                        <td>{tree.date_planted}</td>
                                        <td>${tree.current_value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                }

            </div>
    )
}