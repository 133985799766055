import { useState } from "react";

import { Button, Container, Dropdown, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { useHistory } from "react-router-dom";



export default function ClientMobileNav({ logout }) {
    const [show, setShow] = useState(false);
    const history = useHistory()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleNavigate = (path) => {
        history.push(path)
        setShow(false)
    }

    return (
        <>
            <Navbar>
                <Container>
                    <Navbar.Brand href="#home"><img style={{ marginRight: "1em" }} src="https://i.imgur.com/px19yim.png" height="30px" />Las Azucenas</Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar>
                            <Nav>
                                <Nav.Item>
                                    <img onClick={handleShow} src="/menu.svg" alt="menu" />
                                </Nav.Item>

                            </Nav>
                        </Navbar>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img style={{ marginRight: "1em" }} src="/lasazucenas.png" height="30px"/>Las Azucenas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <hr />
                        <Button onClick={() => handleNavigate('/marketplace')} variant="success" size="lg">
                            Marketplace
                        </Button>
                        <hr />
                        <Button onClick={() => handleNavigate('/my-trees')} variant="success" size="lg">
                            My Trees
                        </Button>
                        <hr />
                        <Button onClick={() => handleNavigate('/my-documents')} variant="success" size="lg">
                            My Documents
                        </Button>
                        <hr />
                        <Nav className="flex-column">
                            <Nav.Item onClick={() => handleNavigate('/my-trees/listings')}>
                                My Listings
                            </Nav.Item>
                            <hr />

                        </Nav>
                        <hr />
                        <Button onClick={() => handleNavigate('/faq')} variant="success" size="lg">
                            FAQ
                        </Button>
                        <hr />
                        <Button onClick={() => logout()} variant="outline-success" size="lg">
                            Logout
                        </Button>

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}