import { useParams } from "react-router-dom"
import './bos.scss'
import { useEffect, useState, useRef } from 'react';
import { Alert, Button, Card, ListGroup, Table, Modal, Spinner, Dropdown, Form, Row, Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SignatureCanvas from 'react-signature-canvas'
import ErrorPage from "../../utilities/errorPage";
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';

export default function SignBOS() {
    const [data, setData] = useState('')
    const [documentData, setDocumentData] = useState({})
    const [isComplete, setIsComplete] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [sign, setSign] = useState('')
    const [show, setShow] = useState(false);
    const [signature, setSignature] = useState('')
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [sessionId, setSessionId] = useState(null)
    const [showDraw, setShowDraw] = useState(false)
    const [trees, setTrees] = useState([])
    const { document_id } = useParams()
    const [validInput, setValidInput] = useState(false)
    const [forCompany, setForCompany] = useState(false)
    const [organizationName, setOrganizationName] = useState(null)
    const [acknowledge, setAcknowledge] = useState(false);
    const pad = useRef()
    const history = useHistory()
    const checkboxRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        getDocumentData()
        let bos_id = sessionStorage.getItem('bos')
        let sessionFirstName = sessionStorage.getItem('firstName')
        let sessionLastName = sessionStorage.getItem('lastName')
        let sessionOrg = sessionStorage.getItem('organizationName')
        if (sessionFirstName !== null && sessionLastName !== null) {
            setFirstName(sessionFirstName)
            setLastName(sessionLastName)
            setValidInput(true)
        }
        if (sessionOrg !== 'null' && sessionOrg !== undefined && sessionOrg !== null) {
            setForCompany(true)
            setOrganizationName(sessionOrg)
        }
        setSessionId(bos_id)
    }, [])

    useEffect(() => {
        if (signature !== '') {
            if (!acknowledge) {
                checkboxRef?.current?.focus();
                Swal.fire({ icon: 'info', title: 'You must acknowledge that you have received and read the white paper.', text: 'There is a check box a few lines above the signature area.' })
                return
            }
            setShow(true)
        }
    }, [signature, acknowledge])

    const getDocumentData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
        };
        try {
            fetch(process.env.REACT_APP_API + '/document/' + document_id + '/bos', requestOptions)
                .then((response => response.json())).then((res) => {
                    if (res.data[0]) {
                        setDocumentData(res.data[0])
                        setTrees(res.trees)
                        setLoading(false)
                        if (res.data[0].complete || res.data[0].party_one_data !== null) {
                            setIsComplete(true)
                            history.push('/payment-instructions')
                        }
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                })
        } catch (err) {
            setError(true)
            console.log(err)
        }
    }
    const returnTotal = () => {
        if (documentData.custom_total === '0.00' || documentData.custom_total == null) {
            return formatter.format(documentData.default_total)
        } else {
            return formatter.format(documentData.custom_total)
        }
    }

    const captureSig = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
    }

    const handleSubmit = () => {
        if (!acknowledge) {
            checkboxRef?.current?.focus();
            Swal.fire({ icon: 'info', title: 'You must acknowledge that you have read the white paper.' })
            return
        }
        if (signature === '') {
            Swal.fire({ icon: 'info', title: 'Please sign the document before submitting.' })
            return
        }
        setLoading(true)
        let data = {
            buyerFirstName: firstName,
            buyerLastName: lastName,
            buyerSignature: signature,
            buyerUserAgent: navigator.userAgent,
            buyerTrees: trees,
            forCompany,
            organizationName
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
            body: JSON.stringify({ data })
        };
        try {
            fetch(process.env.REACT_APP_API + '/document/sign/bos/' + document_id, requestOptions)
                .then((response => response.json())).then(res => {
                    if (res.message === 'success') {
                        Swal.fire({ icon: 'success', title: 'Document Successfully Signed' }).then(() => {

                            history.push('/payment-instructions/')

                        })

                    } else {
                        Swal.fire({ icon: 'error', title: 'Sorry we are having technical issues, please reach out to support@goodalways.com' })
                    }
                })
            handleClose()
        } catch (err) {
            console.log(err)
        }
    }
    const handleClose = () => {
        setShow(false)
    }

    const createTextSignature = (id) => {
        // create the element
        const div = document.createElement('div');
        div.innerHTML = firstName + ' ' + lastName;
        div.id = id ?? 'sig1'
        div.classList.add('create-signature')
        document.body.appendChild(div);
        // convert the element to a base64 image
        html2canvas(div, { scale: 2.5 }).then(canvas => {
            const base64Image = canvas.toDataURL();
            setSignature(base64Image);
        });
        document.body.removeChild(div);
    }
    const handleCheckInputs = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidInput(false)
        }
        event.preventDefault();
        setValidInput(true);
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const handleChangeForCompany = (e) => {
        if (e.target.value === 'true') {
            setForCompany(true)
        } else {
            setForCompany(false)
            setOrganizationName(null)
        }
    }
    const handleReturnName = () => {
        if (forCompany) {
            return organizationName
        } else {
            return firstName + ' ' + lastName
        }
    }

    return (
        !isError ?
            <div className="onboarding-demo">
                <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Document System</div>
                <div className="paper-container">
                    {loading ?
                        <div className="paper" style={{ minWidth: '8.5in' }}>
                            <Spinner animation="border" variant="success" size="lg"></Spinner>
                            <h2>Processing...</h2>
                        </div> : <div className="paper">

                            <Alert variant="success">
                                {sessionId !== null ? <>
                                    Thank You for Completing the Management Agreement.
                                    <hr />
                                </> : ''}
                                Please review and complete your portion of this Bill of Sale.

                                {validInput ? '' : <p>
                                    <br />Your Bill of Sale will display below after you enter your first and last name.</p>}

                            </Alert>
                            <Card>
                                <Card.Body>

                                    <Form validated={validInput} onSubmit={handleCheckInputs}>
                                        <Form.Label>Are you signing for yourself or on behalf of an organization?</Form.Label>
                                        <Row>
                                            <Col sm>
                                                <Form.Label>I am signing:</Form.Label>
                                                <Form.Select value={forCompany} disabled={validInput} required onChange={e => handleChangeForCompany(e)}>
                                                    <option value="false">for myself</option>
                                                    <option value="true">on behalf of an organization</option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm>{forCompany ? <>
                                                <Form.Label>Organization Name</Form.Label>
                                                <Form.Control disabled={validInput} required value={organizationName} onChange={e => setOrganizationName(e.target.value)} placeholder="Organization Name"></Form.Control>
                                            </> : ''}</Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col sm>
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control disabled={validInput} required value={firstName} onChange={e => setFirstName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                            </Col>
                                            <Col sm>
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control disabled={validInput} required value={lastName} onChange={e => setLastName(e.target.value)} type='text' placeholder="Last Name"></Form.Control>
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>{!validInput ? <Button type="submit">Submit</Button> : ''}

                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <br />
                            <Row>
                                <Col>
                                    {validInput ? <Button variant="secondary" onClick={() => setValidInput(false)}>Edit First And Last Name</Button> : ''}
                                </Col>
                            </Row>
                            {validInput ? <>
                                <hr />
                                <p></p>
                                <h2>
                                    County of Los Angeles
                                    <br />
                                    State of California
                                </h2>
                                <br />
                                <br />
                                <h1 className="center">
                                    Bill of Sale
                                </h1>
                                <br />
                                <p>


                                    FOR AND IN CONSIDERATION OF the sum of <span className="input-box">{returnTotal()} U.S. Dollars</span>, LOS
                                    SANTOS CONSULTING, LLC DOES HEREBY SELL, ASSIGN, AND TRANSFER to <span className="input-box">{handleReturnName()}</span>, (the "Buyer") the number of <span className="input-box">{trees.length}</span> hardwood tree(s)
                                    in the reforestation farm known as Las Azucenas Farm, located in the department of Petén,
                                    Poptún Municipality, Concomá Village in Guatemala.
                                </p>
                                <p>
                                    Hardwood trees are defined as one of the following three types of biosphere hardwood trees
                                    planted at Las Azucenas Ranch:
                                </p>
                                <p>
                                    Mahogany (Swietenia macrophylla King), Cedar (Cedrella odorata L.) and Ciricote (Cordia
                                    dodecandra Dc.), for restoration purposes in areas degraded by agricultural and cattle-raising
                                    activities.
                                </p>
                                <p>
                                    The tree(s) in this transaction are identified by the following unique inventory number(s):
                                </p>

                                <Row >

                                    <Col>

                                        <Row >
                                            <Col><strong>Tree ID</strong></Col>
                                            <Col><strong>Tree Type</strong></Col>
                                        </Row>
                                        {trees.map((tree, index) => (
                                            <Row className="tree-item-bos" key={index + 'tree'}>
                                                <Col>{tree.tree_id}</Col>
                                                <Col>{tree.tree_type}</Col>
                                            </Row>
                                        ))}



                                    </Col>
                                    <Col></Col>
                                </Row>

                                <br />
                                <p>
                                    The Seller warrants that (1) the Seller is the legal owner of the tree(s); (2) the tree(s) are being transferred free and clear of all mortgages, liens or encumbrances of any nature whatsoever, and Seller shall indemnify, defend and hold Buyer harmless against such claims and demands; (3) the Seller has the right to sell the Property and the trees; and (4) the trees are healthy, but makes no further warranties, whether expressed or implied (except as otherwise specifically stated in this Bill of Sale).
                                </p>
                                <p>
                                    The Buyer will be given a picture of their tree as all trees are inventoried.
                                </p>
                                <p>
                                    In the event any dispute between the parties hereto should result in litigation or arbitration, the prevailing party shall be reimbursed for all reasonable costs in connection therewith, including, but not limited to, reasonable attorney's fees and defense costs. In no event shall either party be liable for incidental, consequential, indirect or special damages of any kind, including, but not limited to, loss of profit.
                                </p>
                                <p>
                                    The terms of this Bill of Sale shall bind and inure to the benefit of the parties hereto and their respective heirs, legal representatives, successors and assigns.
                                </p>
                                <p>
                                    If you are buying these trees for someone else after your purchase is complete Los Santos will provide instructions on how to transfer them into their names.
                                </p>
                                <Alert>
                                    <Form.Check // prettier-ignore
                                        type={'checkbox'}
                                        id={`acknowledge`}
                                        ref={checkboxRef}
                                        label={<>I acknowledge that I have received and read the <a href='https://azucenas.org/azucenas_white_paper.pdf' target='_blank' rel='noreferrer'>Las Azucenas white paper</a>. This overview
                                            outlines key details about the Las Azucenas timber cultivation project, including:</>}
                                        onChange={e => setAcknowledge(e.target.checked)}

                                    />
                                </Alert>


                                <ul>
                                    <li>Background on the company and management team</li>
                                    <li>Track record</li>
                                    <li>Location and species of trees being offered</li>
                                    <li>Growth cycles and estimated timeline to maturity</li>
                                    <li>Historical present and proforma tree prices</li>
                                    <li>Explanation of land lease arrangements</li>
                                    <li>Responsibilities for crop care and maintenance</li>
                                    <li>Commissions, fees, and potential investor exit options</li>
                                    <li>Risk factors such as diseases, pests, fire, weather events, and market fluctuations</li>
                                </ul>
                                <p>The parties hereby agree to execute such other documents and perform such other acts as may be necessary or desirable to carry out the purposes of this Bill of Sale.
                                </p>
                                <p>
                                    This Bill of Sale shall be signed by the Buyer's Representative, IF APPLICABLE and by the Seller's Representative, IF APPLICABLE and shall be effective as of the Date of execution of this Bill. In addition, the Buyer grants equitable rights to the purchased property for the benefit of the Management Services Agreement that the Buyer has entered into concurrently with LOS SANTOS CONSULTING, LLC.


                                </p>
                                <p>
                                    The parties have executed this Bill of Sale on the Date: <span className="date-box">{(new Date()).toDateString()}</span>
                                </p>
                                <Alert>
                                    <Alert.Heading>Buyer or Representative Signature:</Alert.Heading>
                                    <hr />
                                    <p>

                                    </p>

                                    <p>
                                        By:
                                    </p>
                                    {sign === '' ? <Button size="lg" variant="primary" onClick={() => setSign('open')}>Click To Sign</Button> : signature === '' ?
                                        <>
                                            <Alert variant='secondary'>Select A Signature To Use From the Dropdown Menu
                                                <hr />
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        Choose a Signature
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu onChange={e => console.log(e)}>
                                                        <Dropdown.Item id="sig1" onClick={() => createTextSignature('sig1')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                        <Dropdown.Item id="sig2" onClick={() => createTextSignature('sig2')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                        <Dropdown.Item id="sig3" onClick={() => createTextSignature('sig3')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                        <Dropdown.Item id="my-own" onClick={() => setShowDraw(true)}>Draw my own</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown></Alert>

                                            {
                                                !showDraw ? '' :
                                                    <div>
                                                        <p>Draw your signature in the white space below, then click 'Accept Signature'</p>
                                                        <br />
                                                        <div className='signature-block'>
                                                            <SignatureCanvas penColor='Blue'
                                                                canvasProps={{ className: "sigCanvas" }}
                                                                ref={pad}
                                                            />
                                                        </div>
                                                        <Button onClick={() => captureSig()}>Accept Signature</Button>
                                                        <br />
                                                        <br />
                                                    </div>
                                            }
                                        </>
                                        :
                                        <div>
                                            <img src={signature} style={{ maxWidth: '100%' }} /><br />
                                            <p style={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setSignature('')}>Click here to redo signature.</p>
                                        </div>

                                    }

                                    <p>
                                        Date: {(new Date()).toLocaleDateString()}
                                    </p>
                                    <p>
                                        Buyer’s Printed Name: <span className='input-box'>{firstName + ' ' + lastName}</span>

                                    </p>
                                    {forCompany ? <span className='input-box'>On behalf of {organizationName}</span> : ''}

                                </Alert>
                                <br />
                                <Alert variant="secondary">
                                    <Alert.Heading></Alert.Heading>
                                    <hr />
                                    Los Santos will sign their portion separately.
                                    <br />
                                    You will receive a copy of the Bill of Sale after your Payment is complete.
                                    <br />
                                    <br />
                                    <p>
                                        By: ___________________________________ Date: __________________
                                        <br />
                                        Guillermo Alain Romero, On Behalf Of Los Santos Consulting, LLC
                                    </p>
                                </Alert>
                                <br />
                                <div className='button-container'>
                                    <Button size="lg" onClick={() => setShow(true)}>Complete Document</Button>
                                </div>
                            </> : <>
                                <br />

                            </>
                            }

                        </div>}


                </div>
                <Modal show={show} onHide={() => handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Finalize Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You have completed the required fields. Ready to submit?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => handleSubmit()}>
                            Submit Document
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> : <ErrorPage />
    )
}