import Session from "supertokens-auth-react/recipe/session";
import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import { Alert, Button, Form, Modal, Spinner, Tab, Table } from "react-bootstrap"
import Swal from "sweetalert2";
Session.addAxiosInterceptors(axios);
export default function Broker() {
    const [brokerData, setBrokerData] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [checkoutTransfer, setCheckout] = useState({})
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        getBrokerData()
    }, [])
    const getBrokerData = () => {
        axios.get(process.env.REACT_APP_API + '/admin/broker').then(res => {
            setBrokerData(res.data)
            setLoading(false)
        })
    }
    const handlePreviewTransfer = (item) => {
        setCheckout(item)
        setShow(true)
    }
    const handleCheck = (e) => {
        if (e.target.checked) {
            setChecked(true)
        } else { setChecked(false) }
    }
    const handleClose = () => {
        setShow(false)
        setChecked(false)
    }
    const handleSubmitTransfer = () => {
        axios.post(process.env.REACT_APP_API + '/admin/trees/transfer', checkoutTransfer).then(res => {
            if (res.data === 'updated') {
                Swal.fire({ icon: 'success', timer: 2000, title: 'Trees Transferred', showConfirmButton: false }).then(()=>{
                    window.location.reload()
                })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong!'
                })
            }
        })
    }

    return (
        <div className="admin-dash-content">
            <h2>Broker Page</h2>
            <hr />

            {loading ?
                <><Spinner animation="border" variant="success" ></Spinner> loading ...</>
                :
                <div>
                    <Modal show={show} fullscreen onHide={() => handleClose()}>
                        {checkoutTransfer.checkout_items ?
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title>Complete Transfer Of Trees</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert variant="primary">
                                        <h3>You are about to transfer the following trees from
                                            {' ' + checkoutTransfer.sellerData.first_name} {checkoutTransfer.sellerData.last_name} ({checkoutTransfer.sellerData.owner_id}) to {checkoutTransfer.buyerData.first_name} {checkoutTransfer.buyerData.last_name} ({checkoutTransfer.buyerData.owner_id})
                                        </h3>
                                        <br />
                                        <strong>Do not complete this transfer until you have:</strong>
                                        <ol>
                                            <li>Contacted the Seller and Purchased the Tree(s) from them.</li>
                                            <li>Updated the Seller's Bill of Sale to show the trees have been transferred back to Los Santos.</li>
                                            <li>Contacted the buyer and informed them you are updating their bill of sale to show that they have purchased the trees below.</li>
                                        </ol>
                                        <Form.Check
                                            onChange={handleCheck}
                                            type="checkbox"
                                            id={`default-checkbox`}
                                            label={`I have completed the above items.`}
                                        />
                                        <br />
                                        <Button onClick={() => handleSubmitTransfer()} disabled={!checked}>Complete Transfer</Button>
                                    </Alert>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Tree Type
                                                </th>
                                                <th>
                                                    Tree ID
                                                </th>
                                                <th>
                                                    Price Paid
                                                </th>
                                                <th>
                                                    Tree Age
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkoutTransfer.checkout_items ? JSON.parse(checkoutTransfer.checkout_items).map((checkout, index2) => (
                                                <tr>
                                                    <td>{checkout.tree_type}</td>
                                                    <td>{checkout.tree_id}</td>
                                                    <td>${checkout.value}</td>
                                                    <td>{checkout.months} months</td>
                                                </tr>

                                            )) : ''}
                                        </tbody>
                                    </Table>
                                    <hr />



                                </Modal.Body>
                            </>
                            : ''
                        }

                    </Modal>
                    {brokerData.map((item, index) => (
                        <Alert>
                            {console.log(item)}
                            <Alert.Heading>{new Date(item.date).toDateString()}</Alert.Heading>
                            <p>{item.buyerData.first_name} {item.buyerData.last_name} ({item.buyerData.owner_id}) Purchased the following trees from {item.sellerData.first_name} {item.sellerData.last_name} ({item.sellerData.owner_id})</p>
                            <hr />
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>
                                            Tree Type
                                        </th>
                                        <th>
                                            Tree ID
                                        </th>
                                        <th>
                                            Price Paid
                                        </th>
                                        <th>
                                            Tree Age
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {JSON.parse(item.checkout_items).map((checkout, index2) => (
                                        <tr>
                                            <td>{checkout.tree_type}</td>
                                            <td>{checkout.tree_id}</td>
                                            <td>${checkout.value}</td>
                                            <td>{checkout.months} months</td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>
                            <Button onClick={() => handlePreviewTransfer(item)}>Complete Transfer</Button>
                        </Alert>
                    ))}
                </div>
            }

        </div>
    )
}