import { useEffect, useState } from "react";
import { Container, Nav, Navbar, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import './MyTrees.scss'
import MyTreeTable from "./myTreeTable";
import Session from "supertokens-auth-react/recipe/session";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import axios from "axios";
import MyListings from "./listings";
Session.addAxiosInterceptors(axios);
export default function MyTrees({ userInfo }) {
    console.log(userInfo)
    const history = useHistory()
    const { userId } = useSessionContext()
    const [trees, setTrees] = useState([])
    const [pending, setPending] = useState([])
    const [loading, setLoading] = useState(true)
    const [graphData, setGraphData] = useState([])
    useEffect(() => {
        getTrees()
    }, [])

    const getTrees = async () => {
        setLoading(true)
        await axios.get(process.env.REACT_APP_API + '/user/trees/' + userId).then(res => {
            setTrees(res.data)
        })
        await axios.get(process.env.REACT_APP_API + '/user/trees/' + userId + '/pending').then(res => {
            setPending(res.data)
            // setLoading(false)
        })
        await axios.get(process.env.REACT_APP_API + '/user/trees/' + userId + '/projections').then(res => {
            setGraphData(res.data)
            setLoading(false)
        })
    }

    return (
        <div className="client-dash-content">
            <div className="top-nav">
                {/* <Container> */}
                <Nav variant="tabs" defaultActiveKey="/my-trees">
                    <Nav.Item>
                        <Nav.Link eventKey="/my-trees" onClick={() => history.push('/my-trees')}>My Trees</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="/my-trees/listings" onClick={() => history.push('/my-trees/listings')}>Listings</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link eventKey="/my-trees/history" onClick={() => history.push('/my-trees/history')}>History</Nav.Link>
                    </Nav.Item> */}
                </Nav>
                {/* </Container> */}

            </div>



            <Switch>
                <Route exact path="/my-trees/">

                    {loading ? <Spinner animation="border" variant="success" size="lg" /> : <MyTreeTable userInfo={userInfo} pending={pending} trees={trees} graphData={graphData} />}

                </Route>
                <Route path="/my-trees/listings">
                    {loading ? <Spinner animation="border" variant="success" size="lg" /> : <MyListings trees={trees} getTrees={getTrees} />}

                </Route>
                <Route path="/my-trees/history">
                    <h1>History</h1>
                </Route>
            </Switch>

        </div>
    )
}