import { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import Session from "supertokens-auth-react/recipe/session";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import axios from "axios";
Session.addAxiosInterceptors(axios);

export default function Projections({info}) {
    // const [info, setinfo] = useState([])
    const { userId } = useSessionContext()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        console.log(info)
        getProjections()
    }, [])
    const getProjections = async () => {
        // await axios.get(process.env.REACT_APP_API + '/user/trees/' + userId + '/projections').then(res => {
            // setinfo(info)
        //     // console.log(res.data)
            setLoading(false)
        // })
    }


    const createData = (index) => {
        let data = {
            labels: info[index].yearsArray,
            datasets: [{
                label: 'Projected Value of Trees planted in ' + info[index].date.substring(0, 4),
                data: info[index].totalValueArray,
                borderColor: 'seagreen',
                backgroundColor: ['#4BA479', '#4BA479', '#4AA077', '#4AA077', '#499D74', '#499D74', '#499972', '#499972', '#489570', '#489570', '#47916E', '#47916E', '#47916E', '#468E6B', '#468E6B', '#468A69', '#468A69', '#458667', '#458667'],
            },],
        }
        return data
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false
    }

    return (
        loading ? 'Loading...' :
            <div>
                {
                    info.map((item, index) => (
                        <div>
                            <hr/>
                            <strong>Projected Total Catalogue Value of ({item.totalTrees}) trees planted in {item.date.substring(0,4)}</strong>
                            <hr/>
                            <div style={{ width: '100%', height: '400px' }}>

                                <Bar data={createData(index)} options={options} />
                            </div>
                        </div>
                    ))
                }
         
            </div>



    )
}