
import './demo.scss'
import { useEffect } from 'react';
import { useState } from 'react'
import { Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
export default function OnBoardingDemo4() {
    const history = useHistory()
    return (
        <div className="onboarding-demo">
            <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Document System</div>
            <div className="paper-container">
                <div className="paper">
                    <Alert variant="primary">Thank you for completing your portion of the bill of sale.</Alert>
                    <strong>The bill of sale will become valid once payment is processed. <br />If you have already made payment you will receive a copy of your bill of sale after Las Azucenas completes the document.</strong>
                    <hr />
                    {/* <ul>
                        <li><strong>Payment options:</strong></li>
                      

                    </ul> */}
                    <p>Please email <strong>alain@azucenas.org</strong> with any questions you may have.</p>
                </div>
            </div>
        </div>
    )
}