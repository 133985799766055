import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Badge, Button, Spinner, Table, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import convertDoctype from "../../../../utilities/convertDocType";
import formatTimestamp from "../../../../utilities/formatTimestamp";


export default function AllDocuments() {
    const [documents, setDocuments] = useState([])
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('');
    useEffect(() => {
        getAllDocuments()
    }, [])

    const getAllDocuments = () => {
        try {
            axios.get(process.env.REACT_APP_API + '/admin/documents').then(res => {
                const docs = res.data.documents.filter(e=> e.type !== 'other')
                setDocuments(docs)
                setLoading(false)
            })
        } catch {
            setLoading(false)
            alert('Something is not working right, contact Taylor')
        }
    }
    function filterByFirstNameOrLastName(arr) {
        return arr.filter(obj => {
            let combine = obj.first_name + ' ' + obj.last_name
            let other = obj.document_name
            // This accounts for onboarding documents
            return combine.toLowerCase().includes(search.toLowerCase()) || other.toLowerCase().includes(search.toLowerCase())
        }

        );
    }

    return (
        <div className="admin-dash-content">{
            loading ? <Spinner animation="border" variant="success" size="lg"></Spinner> : <h2>Document Center</h2>
        }

            <hr />
            <Form.Control
                type="search"
                placeholder="Search by Name"
                className="me-2"
                aria-label="Search"
                onChange={e => setSearch(e.target.value)}
            />
            <hr />
            <div>
                <Table bordered>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Date Created</th>
                            <th>Client Status</th>
                            <th>Admin Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterByFirstNameOrLastName(documents).map((item, index) => (
                            <tr>
                                <td><Button onClick={() => history.push('/document/' + item.document_id + '/' + item.type)}>Open</Button></td>
                                <td>{item.first_name === null ? item.document_name : item.first_name + ' ' + item.last_name}</td>
                                <td>
                                    {convertDoctype(item.type, item)}
                                </td>
                                <td>{formatTimestamp(item.date_created)}</td>
                                <td>{item.party_one_data ? <Badge bg="success">Completed</Badge> : <Badge bg="danger">Incomplete</Badge>}</td>
                                <td>{item.party_two_data ? <Badge bg="success">Completed</Badge> : <Badge bg="danger">Incomplete</Badge>}</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        </div>
    )
}