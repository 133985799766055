import { useParams } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { Alert, Button, Card, ListGroup, Table, Modal, Spinner, Dropdown, Form, Row, Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SignatureCanvas from 'react-signature-canvas'
import ErrorPage from "../../../../utilities/errorPage"
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import formatTimestamp from "../../../../utilities/formatTimestamp";
import axios from "axios";
import ProcessingDocument from "../processingPage";

export default function AdminSignBOS() {
    const [data, setData] = useState('')
    const [documentData, setDocumentData] = useState({})
    const [isComplete, setIsComplete] = useState(false)
    const [firstName, setFirstName] = useState('Alain')
    const [lastName, setLastName] = useState('Romero')
    const [sign, setSign] = useState('')
    const [show, setShow] = useState(false);
    const [signature, setSignature] = useState('')
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [sessionId, setSessionId] = useState(null)
    const [showDraw, setShowDraw] = useState(false)
    const [trees, setTrees] = useState([])
    const { document_id } = useParams()
    const [validInput, setValidInput] = useState(false)
    const [buyerData, setBuyerData] = useState()
    const [processingPDF, setProcessingPDF] = useState(false)
    const pad = useRef()
    const history = useHistory()


    useEffect(() => {
        window.scrollTo(0, 0)
        getDocumentData()
    }, [])

    useEffect(() => {
        if (signature !== '') {
            setShow(true)
        }
    }, [signature])

    const getDocumentData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
        };
        try {
            fetch(process.env.REACT_APP_API + '/document/' + document_id + '/bos', requestOptions)
                .then((response => response.json())).then((res) => {
                    if (res.data[0]) {
                        setDocumentData(res.data[0])
                        setBuyerData(JSON.parse(res.data[0].party_one_data))
                        setTrees(res.trees)
                        setLoading(false)
                        if (res.data[0].complete || res.data[0].party_two_data !== null) {
                            setIsComplete(true)
                            history.push('/documents')
                        }
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                })
        } catch (err) {
            setError(true)
            console.log(err)
        }
    }
    const returnTotal = () => {
        if (documentData.custom_total === '0.00' || documentData.custom_total == null) {
            return formatter.format(documentData.default_total)
        } else {
            return formatter.format(documentData.custom_total)
        }
    }
    const test = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
        setTimeout(() => {
            setShow(true)
        }, 2000)
    }
    const captureSig = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
    }

    const handleSubmit = () => {
        if (signature === '') {
            Swal.fire({ icon: 'info', title: 'Please sign the document before submitting.' })
            return
        }
        setLoading(true)
        let data = {
            sellerFirstName: firstName,
            sellerLastName: lastName,
            sellerSignature: signature,
            sellerUserAgent: navigator.userAgent,
            sellerTrees: trees
        }

        try {
            axios.post(process.env.REACT_APP_API + '/admin/sign/bos/' + document_id, data).then(res => {
                if (res.data.message === 'success') {
                    setProcessingPDF(true)
                    Swal.fire({ icon: 'success', title: 'Document Successfully Signed', text: 'The system will now build the pdf and send email copies to each party.' })
                } else {
                    Swal.fire({ icon: 'error', title: 'Something did not work correctly, please reach out to Taylor for help.' })
                }
            })

            handleClose()
        } catch (err) {
            console.log(err)
        }
    }
    const handleClose = () => {
        setShow(false)
    }

    const createTextSignature = (id) => {
        // create the element
        const div = document.createElement('div');
        div.innerHTML = firstName + ' ' + lastName;
        div.id = id ?? 'sig1'
        div.classList.add('create-signature')
        document.body.appendChild(div);
        // convert the element to a base64 image
        html2canvas(div, { scale: 2.5 }).then(canvas => {
            const base64Image = canvas.toDataURL();
            setSignature(base64Image);
        });
        document.body.removeChild(div);
    }
    const handleCheckInputs = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidInput(false)
        }
        event.preventDefault();
        setValidInput(true);
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const returnName = () => {
        console.log(buyerData)
        if (buyerData.data.forCompany) {
            return 'company'
        } else {
            return buyerData.data.buyerFirstName + ' ' + buyerData.data.buyerLastName
        }
    }

    return (
        processingPDF ?
            <div className="admin-dash-content">
                <ProcessingDocument type='bos' />
            </div> :
            !isError ?
                <div className="onboarding-demo">
                    <div className="paper-container">
                        {loading ?
                            <div className="paper" style={{ minWidth: '8.5in' }}>
                                <Spinner animation="border" variant="success" size="lg"></Spinner>
                                <h2>Processing...</h2>
                            </div> : <div className="paper">

                                <Alert variant="success">
                                    <h2>Admin Document Signing:</h2>
                                    <hr />
                                    Confirm your name, then click submit.
                                </Alert>
                                <Form validated={validInput} onSubmit={handleCheckInputs}>
                                    <Row>
                                        <Col sm>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control disabled={validInput} required value={firstName} onChange={e => setFirstName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                        </Col>
                                        <Col sm>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control disabled={validInput} required value={lastName} onChange={e => setLastName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>{!validInput ? <Button type="submit">Submit</Button> : ''}

                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col>
                                        {validInput ? <Button variant="secondary" onClick={() => setValidInput(false)}>Edit First And Last Name</Button> : ''}
                                    </Col>
                                </Row>
                                {validInput ? <>
                                    <hr />
                                    <p></p>
                                    <h2>
                                        County of Los Angeles
                                        <br />
                                        State of California
                                    </h2>
                                    <br />
                                    <br />
                                    <h1 className='center'>
                                        Bill of Sale
                                    </h1>
                                    <br />
                                    <p>
                                        FOR AND IN CONSIDERATION OF the sum of <span className="input-box">{returnTotal()} U.S. Dollars</span>, LOS
                                        SANTOS CONSULTING, LLC DOES HEREBY SELL, ASSIGN, AND TRANSFER to <span className="input-box">{returnName()}</span>, (the "Buyer") the number of <span className="input-box">{trees.length}</span> hardwood tree(s)
                                        in the reforestation farm known as Las Azucenas Farm, located in the department of Petén,
                                        Poptún Municipality, Concomá Village in Guatemala.
                                    </p>
                                    <p>
                                        Hardwood trees are defined as one of the following three types of biosphere hardwood trees
                                        planted at Las Azucenas Ranch:
                                    </p>
                                    <p>
                                        Mahogany (Swietenia macrophylla King), Cedar (Cedrella odorata L.) and Ciricote (Cordia
                                        dodecandra Dc.), for restoration purposes in areas degraded by agricultural and cattle-raising
                                        activities.
                                    </p>
                                    <p>
                                        The tree(s) in this transaction are identified by the following unique inventory number(s):
                                    </p>

                                    <Row >

                                        <Col>

                                            <Row >
                                                <Col><strong>Tree ID</strong></Col>
                                                <Col><strong>Tree Type</strong></Col>
                                            </Row>
                                            {trees.map((tree, index) => (
                                                <Row className="tree-item-bos" key={index + 'tree'}>
                                                    <Col>{tree.tree_id}</Col>
                                                    <Col>{tree.tree_type}</Col>
                                                </Row>
                                            ))}



                                        </Col>
                                        <Col></Col>
                                    </Row>

                                    <br />
                                    <p>
                                        The Seller warrants that (1) the Seller is the legal owner of the tree(s); (2) the tree(s) are being transferred free and clear of all mortgages, liens or encumbrances of any nature whatsoever, and Seller shall indemnify, defend and hold Buyer harmless against such claims and demands; (3) the Seller has the right to sell the Property and the trees; and (4) the trees are healthy, but makes no further warranties, whether expressed or implied (except as otherwise specifically stated in this Bill of Sale).
                                    </p>
                                    <p>
                                        The Buyer will be given a picture of their tree as all trees are inventoried.
                                        In the event any dispute between the parties hereto should result in litigation or
                                        arbitration, the prevailing party shall be reimbursed for all reasonable costs in connection
                                        therewith, including, but not limited to, reasonable attorney's fees and defense costs. In no event
                                        shall either party be liable for incidental, consequential, indirect or special damages of any kind,
                                        including but not limited to loss of profit.
                                    </p>
                                    <p>
                                        The terms of this Bill of Sale shall bind and inure to the benefit of the parties hereto and
                                        their respective heirs, legal representatives, successors and assigns.
                                    </p>
                                    <p>
                                        If you are buying these trees for someone else after your purchase is complete we will provide instructions on how to transfer them into their names.
                                    </p>

                                    <Form.Check // prettier-ignore
                                        type={'checkbox'}
                                        id={`acknowledge`}
                                        checked={true}
                                        disabled={true}
                                        style={{opacity: 1}}
                                        label={<>I acknowledge that I have received and read the <a href='https://azucenas.org/azucenas_white_paper.pdf' target='_blank' rel='noreferrer'>Las Azucenas white paper</a>. This overview
                                            outlines key details about the Las Azucenas timber cultivation project, including:</>}

                                    />



                                    <ul>
                                        <li>Background on the company and management team</li>
                                        <li>Track record</li>
                                        <li>Location and species of trees being offered</li>
                                        <li>Growth cycles and estimated timeline to maturity</li>
                                        <li>Historical present and proforma tree prices</li>
                                        <li>Explanation of land lease arrangements</li>
                                        <li>Responsibilities for crop care and maintenance</li>
                                        <li>Commissions, fees, and potential investor exit options</li>
                                        <li>Risk factors such as diseases, pests, fire, weather events, and market fluctuations</li>
                                    </ul>
                                    <p>The parties hereby agree to execute such other documents and perform such other acts as
                                        may be necessary or desirable to carry out the purposes of this Bill of Sale.
                                    </p>
                                    <p>
                                        This Bill of Sale shall be signed by the Buyer's Representative, IF APPLICABLE and by the Seller's Representative, IF APPLICABLE and shall be effective as of the Date of execution of this Bill. In addition, the Buyer grants equitable rights to the purchased property for the benefit of the Management Services Agreement that the Buyer has entered into concurrently with LOS SANTOS CONSULTING, LLC.


                                    </p>
                                    <p>
                                        The parties have executed this Bill of Sale on the Date: <span className="date-box">{(new Date()).toDateString()}</span>
                                    </p>
                                    <Alert variant="secondary">
                                        <Alert.Heading>Buyer or Representative Signature</Alert.Heading>
                                        <hr />
                                        <p>

                                        </p>
                                        <p>
                                            BUYER or Representative Signature

                                        </p>
                                        <p>
                                            By:
                                        </p>
                                        <img src={buyerData.data.buyerSignature} style={{ maxWidth: '100%' }} /><br />


                                        <p>
                                            Date Signed: {formatTimestamp(buyerData.buyerSignedDate)}
                                        </p>
                                        <p>
                                            Buyer’s Printed Name: <span className='input-box'>{buyerData.data.buyerFirstName + ' ' + buyerData.data.buyerLastName}</span>

                                        </p>

                                    </Alert>
                                    <br />
                                    <Alert variant="secondary">
                                        <Alert.Heading>Los Santos Representative Signature:</Alert.Heading>
                                        <hr />

                                        <br />


                                        <br />
                                        {sign === '' ? <Button size="lg" variant="primary" onClick={() => setSign('open')}>Click To Sign</Button> : signature === '' ?
                                            <>
                                                <Alert variant='primary'>Select A Signature To Use From the Dropdown Menu
                                                    <hr />
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            Choose a Signature
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu onChange={e => console.log(e)}>
                                                            <Dropdown.Item id="sig1" onClick={() => createTextSignature('sig1')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                            <Dropdown.Item id="sig2" onClick={() => createTextSignature('sig2')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                            <Dropdown.Item id="sig3" onClick={() => createTextSignature('sig3')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                            <Dropdown.Item id="my-own" onClick={() => setShowDraw(true)}>Draw my own</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown></Alert>

                                                {
                                                    !showDraw ? '' :
                                                        <div>
                                                            <p>Draw your signature in the white space below, then click 'Accept Signature'</p>
                                                            <br />
                                                            <div className='signature-block'>
                                                                <SignatureCanvas penColor='Blue'
                                                                    canvasProps={{ className: "sigCanvas" }}
                                                                    ref={pad}
                                                                />
                                                            </div>
                                                            <Button onClick={() => captureSig()}>Accept Signature</Button>
                                                            <br />
                                                            <br />
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div>
                                                <img src={signature} style={{ maxWidth: '100%' }} /><br />
                                                <p style={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setSignature('')}>Click here to redo signature.</p>
                                            </div>

                                        }
                                        <br />
                                        <br />
                                        <br />
                                        <p>
                                            Guillermo Alain Romero, On Behalf Of Los Santos Consulting, LLC
                                        </p>
                                    </Alert>
                                    <br />
                                    <div className='button-container'>
                                        <Button size="lg" onClick={() => setShow(true)}>Complete Document</Button>
                                    </div>
                                </> : <>
                                    <br />

                                </>
                                }

                            </div>}


                    </div>
                    <Modal show={show} onHide={() => handleClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Finalize Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>You have completed the required fields. Ready to submit?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => handleSubmit()}>
                                Submit Document
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div> : <ErrorPage />
    )
}