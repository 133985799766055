
import './demo.scss'
import { useEffect, useState, useRef } from 'react';
import { Alert, Button, Card, ListGroup, Table, Modal, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SignatureCanvas from 'react-signature-canvas'


export default function OnBoardingDemo3() {
    const [data, setData] = useState('')
    const [sign, setSign] = useState('')
    const [show, setShow] = useState(false);
    const [signature, setSignature] = useState('')
    const [loading, setLoading] = useState(true);

    const pad = useRef()
    const history = useHistory()


    useEffect(() => {
        window.scrollTo(0, 0)
        let info = JSON.parse(localStorage.getItem('documentData'))
        console.log(info)
        setData(info)
        setTimeout(() => {
            setLoading(false)

        }, 3000)
    }, [])

    const test = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
        setTimeout(() => {
            setShow(true)
        }, 2000)
    }

    const handleSubmit = () => {
        history.push('/payment-instructions')
    }
    const handleClose = () => {
        setShow(false)
    }
    return (
        <div className="onboarding-demo">
            <div className="paper-container">
                {loading ?
                    <div className="paper" style={{ minWidth: '8.5in' }}>
                        <Spinner animation="border" variant="success" size="lg"></Spinner>
                        <h2>Processing...</h2>
                    </div> : <div className="paper">
                        <Alert variant="success">Thank You for Completing the Management Agreement.
                            <hr />Before making payment, please review and complete your portion this Bill of Sale. We saved the information you entered on the previous page and populated it in this document.</Alert>
                        <p></p>
                        <h2>
                            County of Los Angeles
                            <br />
                            State of California
                        </h2>
                        <br />
                        <br />
                        <h1 className="center">
                            Bill of Sale
                        </h1>
                        <br />
                        <p>


                            FOR AND IN CONSIDERATION OF the sum of <span className="input-box">$1,350 U.S. Dollars</span>, LOS
                            SANTOS CONSULTING, LLC DOES HEREBY SELL, ASSIGN, AND TRANSFER to <span className="input-box">{data.name}</span>, (the "Buyer") the amount of <span className="input-box">3</span> hardwood tree(s)
                            in the reforestation farm known as Las Azucenas Farm, located in the department of Petén,
                            Poptún Municipality, Concomá Village in Guatemala
                        </p>
                        <p>
                            Hardwood trees are defined as one of the following three types of biosphere hardwood trees
                            planted at Las Azucenas Ranch:
                        </p>
                        <p>
                            Mahogany (Swietenia macrophylla King), Cedar (Cedrella odorata L.) and Ciricote (Cordia
                            dodecandra Dc.), for restoration purposes in areas degraded by agricultural and cattle-raising
                            activities.
                        </p>
                        <p>
                            The tree(s) in this transaction are identified by the unique inventory number(s):
                        </p>
                        <Card>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Tree ID</th>
                                        <th>Type</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>S4-A1-L50</td>
                                        <td>Mahogany</td>
                                        <td>$450</td>
                                    </tr>
                                    <tr>
                                        <td>S4-A1-L50</td>
                                        <td>Mahogany</td>
                                        <td>$450</td>
                                    </tr>
                                    <tr>
                                        <td>S4-A1-L50</td>
                                        <td>Mahogany</td>
                                        <td>$450</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card>
                        <br />
                        <p>
                            The Seller warrants that (1) the Seller is the legal owner of the tree; (2) the tree is being
                            transferred free and clear of all mortgages, liens or encumbrances of any nature whatsoever, and
                            Seller shall indemnify, defend and hold Buyer harmless against such claims and demands; (3) the
                            Seller has the right to sell the Property; and (4) the tree is healthy, but makes no further
                            warranties, whether expressed or implied (except as specifically stated in this Bill of Sale).
                        </p>
                        <p>
                            The Buyer will be given a picture of their tree as all trees are inventoried.
                            In the event any dispute between the parties hereto should result in litigation or
                            arbitration, the prevailing party shall be reimbursed for all reasonable costs in connection
                            therewith, including, but not limited to, reasonable attorney's fees and defense costs. In no event
                            shall either party be liable for incidental, consequential, indirect or special damages of any kind,
                            including but not limited to loss of profit.
                        </p>
                        <p>
                            The terms of this Bill of Sale shall bind and inure to the benefit of the parties hereto and
                            their respective heirs, legal representatives, successors and assigns.
                        </p>
                        <p>
                            If you are buying these trees for someone else after your purchase is complete we will provide instructions on how to transfer them into their names.
                        </p>
                        <p>The parties hereby agree to execute such other documents and perform such other acts as
                            may be necessary or desirable to carry out the purposes of this Bill of Sale.
                        </p>
                        <p>
                            This Bill of Sale shall be signed by the Buyer's Representative, IF APPLICABLE and by
                            the Seller's Representative, IF APPLICABLE and shall be effective as of the Date of execution
                            of this Bill. In addition, the Buyer grants equitable rights to the purchased property for the
                            benefit of the Management Services Agreement that the Buyer has entered into concurrently with
                            LOS SANTOS CONSULTING , LLC.


                        </p>
                        <p>
                            The parties have executed this Bill of Sale on the Date: <span className="date-box">{(new Date()).toDateString()}</span>
                        </p>
                        <Alert>
                            <Alert.Heading>Party receiving Services:</Alert.Heading>
                            <hr />
                            <p>

                            </p>
                            <p>
                                BUYER Signature

                            </p>
                            <p>
                                By:
                            </p>
                            {sign === '' ? <Button size="lg" variant="secondary" onClick={() => setSign('open')}>Click To Sign</Button> : signature === '' ?
                                <>
                                    <Alert variant='secondary'>Draw your signature then press 'Accept Signature'</Alert>
                                    <div className='signature-block'>
                                        < SignatureCanvas penColor='Blue'
                                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                            ref={pad}
                                        />
                                    </div>
                                    <Button onClick={() => test()}>Accept Signature</Button>
                                </>
                                :
                                <img src={signature} />

                            }
                            <br />
                            <br />
                            <p>
                                Date: {(new Date()).toLocaleDateString()}
                            </p>
                            <p>
                                Buyer’s Printed Name: <span className='input-box'>{data.name}</span>

                            </p>

                        </Alert>
                        <br />
                        <Alert variant="secondary">
                            <Alert.Heading>Party providing services:</Alert.Heading>
                            <hr />
                            Los Santos will sign their portion separately.
                            <br />
                            You will receive a copy of the Bill of Sale after your Payment is complete.
                            <br />
                            <br />
                            <p>
                                By: ___________________________________ Date: __________________
                                <br />
                                Guillermo Alain Romero, On Behalf Of Los Santos Consulting, LLC
                            </p>
                        </Alert>
                        <br />
                        <div className='button-container'>
                            <Button size="lg" onClick={() => setShow(true)}>Complete Document</Button>
                        </div>
                    </div>}


            </div>
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Finalize Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have completed the required fields. Ready to submit?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Submit Document
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}