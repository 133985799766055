import axios from "axios";
import { useState } from "react"
import { Badge, Button, Card, Form, FormControl, FormLabel, InputGroup, ListGroup, ListGroupItem, Modal, Offcanvas, Spinner } from "react-bootstrap"
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom"


export default function VendorPage({ treeList }) {
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [cart, setCart] = useState([])
    const [loading, setLoading] = useState(false)
    const handleClose = () => setShow(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShow = () => setShow(true);
    const handleShowModal = () => setShowModal(true);
    const history = useHistory()
    const { id } = useParams()

    const handleAddToCart = (tree) => {
        setCart([...cart, tree])
        handleShow(true)
    }
    const handleRemoveFromCart = (tree) => {

        setCart([...cart.filter(e => e.tree_id !== tree.tree_id)])
        handleShow(true)
    }
    const calcTotal = () => {
        let total = 0.00
        cart.map((item, index) => {
            let count = +item.current_value + total
            total = count
        })
        return total.toFixed(2)
    }
    const handleCheckout = () => {
        setLoading(true)
        let windowReference = window.open();
        axios.post(process.env.REACT_APP_API + '/user/checkout', {trees: cart}).then(res=>{
            windowReference.location = res.data.url
           setLoading(false)
           history.push('/marketplace')
        })
    }
    return (
        <div className="vendor-page">
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <h1>Vendor {id}</h1>
                <Button onClick={handleShow} variant="success" style={{ display: 'flex', alignItems: 'center' }}>
                    <h3 style={{ margin: '0' }}>My Cart</h3>
                    <img style={{ marginLeft: '12px' }} src="/cart-white.svg" width="25px" />
                </Button>


            </div>

            <hr />
            <Offcanvas show={show} onHide={handleClose} placement={'end'} >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img src="/cart.svg" width="25px" /> Your Tree Cart</Offcanvas.Title>

                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>
                        (You can only purchase from one vendor at a time.)
                    </p>
                    <ListGroup>
                        {cart.map((item, index) => (
                            <ListGroupItem className="d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.tree_type}</div>
                                    <p>ID: {item.tree_id}</p>
                                    <p>Age: {Math.floor(item.months / 12).toFixed(0)} years {item.months % 12} months</p>
                                </div>
                                <strong>${item.current_value}</strong>
                            </ListGroupItem>
                        ))}


                    </ListGroup>
                    <hr />
                    <ListGroup variant="flush">
                        <ListGroupItem className="d-flex justify-content-between align-items-start">
                            <h2>Total</h2> <h2>${calcTotal()}</h2>
                        </ListGroupItem>
                    </ListGroup>
                    <hr />

                    <Button disabled={cart.length < 1} onClick={handleShowModal} variant="success" size="lg">Checkout</Button>
                </Offcanvas.Body>
            </Offcanvas>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                {treeList.filter(e => e.owner_id === +id)[0].trees.map((tree, index) => (
                    <Card style={{ width: '18rem', marginRight: '12px', marginBottom: '12px' }}>
                        <Card.Body>
                            <Card.Title> <img style={{ borderRadius: '50%', border: 'solid 3px seagreen' }} src="/tree-icon.svg" width="50px" /> {tree.tree_type}</Card.Title>
                            <hr />
                            <Card.Subtitle className="mb-2">Age: {Math.floor(tree.months / 12).toFixed(0)} years {tree.months % 12} months</Card.Subtitle>
                            <Card.Text>
                                <ListGroup>
                                    <ListGroupItem className="d-flex justify-content-between align-items-start">Tree ID: <span>{tree.tree_id}</span></ListGroupItem>

                                    <ListGroupItem className="d-flex justify-content-between align-items-start">Price: <h4><Badge bg="secondary" >${tree.current_value}</Badge></h4></ListGroupItem>
                                </ListGroup>

                            </Card.Text>
                            <hr />

                            <InputGroup className="mb-3">
                                {cart.filter(e => e.tree_id === tree.tree_id).length > 0 ?
                                    <Button onClick={() => handleRemoveFromCart(tree)} variant="outline-danger" id="button-addon2">
                                        Remove From Cart
                                    </Button>
                                    :
                                    <Button onClick={() => handleAddToCart(tree)} variant="outline-success" id="button-addon2">
                                        Add To Cart
                                    </Button>}

                            </InputGroup>
                        </Card.Body>
                    </Card>
                ))}
            </div>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                {loading ? <Spinner /> : <Spinner /> }
                    <Modal.Title>Redirecting to Stripe Checkout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                                    
                    We process all our payments through stripe, a powerful and secure online payment system.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel Transaction
                    </Button>
                    {loading ?       <Spinner animation="border" variant="success" size="lg" /> : <Button onClick={() => handleCheckout()} variant="primary">Ok</Button>}
                </Modal.Footer>
            </Modal>
        </div>
    )
}