import Session from "supertokens-auth-react/recipe/session";
import axios from 'axios'
import { useState } from 'react'
import { Alert, Button, FloatingLabel, Form, ListGroup, ListGroupItem } from 'react-bootstrap'
import './UpdateBill.scss'
import Swal from "sweetalert2";
Session.addAxiosInterceptors(axios);

export default function UpdateBill(userInfo) {
    const [newLink, setNewLink] = useState('')


    const handleSubmit = async () => {
        axios.post(process.env.REACT_APP_API + '/admin/user/' + userInfo.userInfo.owner_id + '/bill', { bill_of_sale: newLink }).then(res => {
            if (res.data === 'success') {
                Swal.fire({ title: 'Success', icon: 'success' }).then(result => {
                    window.location.reload()
                })

            }
        })
    }
    return (
        <div className="bill-of-sale">
            <Alert variant="secondary">
                <h2>Update Bill of Sale Link</h2>
            </Alert>
            <ListGroup.Item action onClick={() => window.open(userInfo.userInfo.bill_of_sale, '_blank')} variant="primary">
                {userInfo.userInfo.bill_of_sale}
            </ListGroup.Item>
            <Form>
                <FloatingLabel controlId="floatingInputGrid" label="New Link to Bill Of Sale:">
                    <Form.Control onChange={e => setNewLink(e.target.value)} value={newLink} type="text">

                    </Form.Control>
                </FloatingLabel>
                <Button onClick={handleSubmit} variant="primary">Submit Change</Button>
            </Form>
        </div>
    )
}