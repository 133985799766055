import { Alert } from "react-bootstrap";

export default function RemoveTree () {
    return(
        <div style={{marginTop: '1em'}}>
            <Alert variant="danger">
                <h2>Unavailable</h2>
                <hr/>
                <p>This feature is not yet available but will be in the future. <br/>Contact Taylor if you made a mistake and need to remove trees from an account. He can help you do that.</p>
            </Alert>
        </div>
    )
}