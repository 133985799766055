import axios from "axios";
import { useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2";


const PlaceOrderExistingTrees = () => {
    const history = useHistory()
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        sectorOneTrees: 0,
        sectorTwoTrees: 0,
        sectorThreeTrees: 0,
        sectorFourTrees: 0,
        additionalComments: '',
        orderType: 'existing trees',
        date: new Date().toLocaleDateString()
    });
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [loading, setLoading] = useState(false);

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // You can perform additional actions here with the form data
        const requestOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
            body: JSON.stringify({ data: formData })
        };
        try {
            setLoading(true)
            fetch(process.env.REACT_APP_API + '/public/order-existing', requestOptions)
                .then((response => response.json())).then(res => {
                    window.scrollTo(0, 0);
                    setLoading(false)
                    if (res.message === 'success') {

                        setOrderPlaced(true)
                        Swal.fire({ icon: 'success', title: 'Order Submitted' }).then(() => {

                        })

                    } else {
                        Swal.fire({ icon: 'error', title: 'Sorry we are having technical issues, please reach out to info@azucenas.org' })
                    }
                })
        } catch {
            setLoading(false)
            Swal.fire({ icon: 'error', title: 'Sorry we are having technical issues, please reach out to info@azucenas.org' })
        }
    };

    return (
        <div>
            <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Online Orders</div>
            <div className="paper-container">
                <div className="paper">
                    {loading && <Spinner animation="border" variant="success" size="xl"></Spinner>}
                    {!loading && orderPlaced && <Alert>
                        <Alert.Heading>Order Placed</Alert.Heading><p>An Email was sent to <strong>{formData.email}</strong> containing your order. We will be in contact with you soon.</p></Alert>}
                    {!loading && !orderPlaced && <Form onSubmit={handleSubmit}>
                        <h2>Existing Inventory Order Form</h2>
                        <hr />
                        <Row>

                            <Col lg={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your first name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your last name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg={6}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                            </Col>
                            <Col lg={6}>

                                <Form.Group controlId="phoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter your phone number"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <br />
                        <em>Specify the number of trees you would like to buy, by year of planting.
                        </em>
                        <br />
                        <br />
                        <Row>
                            <Col lg={12}>
                                <Form.Group controlId="sectorOneTrees">
                                    <Form.Label>Trees planted in 2018</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of trees"
                                        name="sectorOneTrees"
                                        value={formData.sectorOneTrees}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="sectorTwoTrees">
                                    <Form.Label>Trees planted in 2019</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of trees"
                                        name="sectorTwoTrees"
                                        value={formData.sectorTwoTrees}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="sectorThreeTrees">
                                    <Form.Label>Trees planted in 2020</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of trees"
                                        name="sectorThreeTrees"
                                        value={formData.sectorThreeTrees}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="sectorFourTrees">
                                    <Form.Label>2021 Trees</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of trees"
                                        name="sectorFourTrees"
                                        value={formData.sectorFourTrees}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Form.Group controlId="additionalComments">
                                    <Form.Label>Additional Comments</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Optional: Enter additional comments"
                                        name="additionalComments"
                                        value={formData.additionalComments}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Button variant="secondary"size="lg" style={{marginRight: '1rem'}} onClick={()=>history.push('/place-order')}>Cancel</Button>
                        <Button variant="success" size="lg" type="submit">
                            Submit Order
                        </Button>
                    </Form>}
                </div>
            </div>
        </div>
    )
}

export default PlaceOrderExistingTrees