import axios from "axios";
import { useState } from "react";
import { Button, Card, ListGroup, ListGroupItem, Spinner, Table } from "react-bootstrap";


export default function Logs() {
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState([])

    const getLogs = async () => {
        setLoading(true)
        try {
            await axios.get(process.env.REACT_APP_API + '/admin/logs').then(res => {
                setLogs(res.data)
                setLoading(false)
            })
        } catch {
            setLoading(false)
            alert('there was a problem')
        }
    }

    return (
        <div className="logs">
            <Card>
                <Card.Header>
                    Logs
                </Card.Header>
                <Card.Body>
                    {loading ? <Spinner animation="border" variant="success" /> : logs.length < 1 ?

                        'Click Button To load' :
                        <Table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.map((item, index) => (
                                    <tr>
                                        <td>{item.log_type}</td>
                                        <td>{new Date(item.date).toLocaleString()}</td>
                                        <td>{item.message}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    }
                </Card.Body>
                <Card.Footer>
                    <Button variant="success" onClick={() => getLogs()}>Get Logs</Button>
                </Card.Footer>
            </Card>
        </div>
    )
}