import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";

import "./AdminDash.scss"
import { Route, Switch } from "react-router-dom";

import AdminNav from "./adminComponents/adminNav";
import AdminMobileNav from "./adminComponents/adminMobileNav";
import Owners from "./adminComponents/owners";
import Owner from "./adminComponents/owner";
import Pricing from "./adminComponents/pricing";
import Broker from "./adminComponents/broker";
import AdminHome from "./adminComponents/adminHome";
import AllTrees from "./adminComponents/allTrees";
import Developer from "./adminComponents/developer";
import SelectTrees from "./adminComponents/selectTrees";
import NewBOS from "./adminComponents/sales/components/newBOS";
import NewClient from "./adminComponents/sales/components/newClient";
import AllDocuments from "./adminComponents/adminDocuments/allDocuments";
import ManageDocument from "./adminComponents/adminDocuments/manageDocument";
import AdminSignBOS from "./adminComponents/adminDocuments/signBos";
import AdminSignMGMT from "./adminComponents/adminDocuments/signManagement";
import OnboardingCenter from "./adminComponents/onboarding";
import MapDemo from "./adminComponents/mapDemo";
import Orders from "./adminComponents/orders";

Session.addAxiosInterceptors(axios);

export default function AdminDash({ logout }) {
    const [show, setShow] = useState(false)
    useEffect(() => {
    }, [])

    return (
        <div className="Admin-Dash">
            <div className="ClientMobileNav">
                <AdminMobileNav logout={logout} />
            </div>
            <AdminNav logout={logout} />
            <div className="Admin-Dash-Body">
                <Switch>
                    <Route exact path="/">
                        <AdminHome />
                    </Route>
                    <Route path="/owners">
                        <Owners />
                    </Route>
                    <Route path="/owner/:id">
                        <Owner />
                    </Route>
                    <Route path="/pricing">
                        <Pricing />
                    </Route>
                    <Route path="/broker">
                        <Broker />
                    </Route>
                    <Route path="/new-client">
                        <NewClient />
                    </Route>
                    <Route path="/trees">
                        <AllTrees />
                    </Route>
                    <Route path="/new-owner/bos">
                        <hr />
                        <NewBOS />
                    </Route>
                    <Route path="/documents">
                        <AllDocuments />
                    </Route>
                    <Route path="/sign/bos/:document_id">
                        <AdminSignBOS />
                    </Route>
                    <Route path="/sign/mgmt/:document_id">
                        <AdminSignMGMT />
                    </Route>
                    <Route path="/document/:document_id/:type">
                        <ManageDocument />
                    </Route>
                    <Route path="/onboarding">
                        <OnboardingCenter />
                    </Route>
                    <Route path="/developer">
                        <Developer />
                    </Route>
                    <Route path="/map-demo">
                        <MapDemo />
                    </Route>
                    <Route path="/orders">
                        <Orders />
                    </Route>
                </Switch>


            </div>
        </div>
    )
}