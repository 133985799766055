import axios from 'axios'
import './Faq.scss'
import { useEffect, useState } from "react";
import { Accordion } from 'react-bootstrap';
import Markdown from 'markdown-to-jsx';

export default function FAQ() {
    const [list, setList] = useState([])

    useEffect(() => {
        getFAQ()
    }, [])
    const getFAQ = () => {
        axios.get(process.env.REACT_APP_API + '/public/faq').then(res => {
            setList(res.data)
        })
    }
    let test = 'this is one line \   this is another'
    return (
        <div className="client-dash-content">
            <h2>Frequently asked questions</h2>

            <hr />
            <Accordion variant="success" defaultActiveKey={['0']} alwaysOpen>
                {list.map((question, index) => (
                    <Accordion.Item eventKey={index} key={index + 'faq'}>
                        <Accordion.Header>{question[0]}</Accordion.Header>
                        <Accordion.Body style={{backgroundColor: 'whitesmoke'}}>
                            {question[1] ? <Markdown>{question[1]}</Markdown> : ''}
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>


        </div>
    )
}