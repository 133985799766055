import './Developer.scss'
import LinkFormatter from './developerComponents/linkFormatter'
import Logs from './developerComponents/logs'
import NewManagement from './developerComponents/newMgmt/newManagement'

export default function Developer() {
    return (
        <div className="admin-dash-content">
            <h1>Developer Page</h1>
            <hr />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Logs />
                <LinkFormatter />
                <NewManagement />
            </div>
        </div>
    )
}