import { useEffect } from 'react';
import { useState } from 'react'
import { Alert, Badge, Card, Container, InputGroup, Navbar } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Bar } from 'react-chartjs-2';
import './calculator.scss'

export default function Calculator() {
    const [trees, setTrees] = useState(1)
    const [cost, setCost] = useState(450)
    const [years, setyears] = useState(18)
    const [feet, setFeet] = useState(450)
    const [price, setPrice] = useState(0)
    const [chartData, setChartData] = useState([])

    useEffect(() => {
    
            handleUpdate()
        
    }, [trees, price, feet])
    const calculatePriceByMonth = (months) => {
        let initialPayment = 450 * trees
        let finalValue = price * feet * trees
        let monthlyIncrease = finalValue / (216)
        let value = (initialPayment + ((+months - 35) * monthlyIncrease)).toFixed(2)
        if (+months < 36) {
            value = 450 * trees
        }
        if (months > 204) {
            value = finalValue
        }
        return value
    }
    const handleUpdate = () => {
        let chartArray = []
        for (let i = 1; i < 19; i++) {
            let months = i * 12
            let value = calculatePriceByMonth(months)
            chartArray.push(+value)
            if (i === 18) {
                setChartData(chartArray)
                console.log(chartArray)
            }
        }
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return '$' + value;
                    }
                }
            }
        }
    }
    let data = {
    
        labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18],
        datasets: [{
            label: 'Tree Catalogue Value',
            data: chartData,
            borderColor: 'seagreen',
            backgroundColor: ['#4BA479', '#4BA479', '#4AA077', '#4AA077', '#499D74', '#499D74', '#499972', '#499972', '#489570', '#489570', '#47916E', '#47916E', '#47916E', '#468E6B', '#468E6B', '#468A69', '#468A69', '#458667', '#458667'],
        },],
    }

    return (
        <div className="calculator-page">
            <Navbar variant="light" style={{ backgroundColor: 'white', width: '100%' }}>
                <Container>
                    <Navbar.Brand style={{ color: 'seagreen', fontWeight: '600' }}> <img src="/lasazucenas.png" width="30px" />Las Azucenas | Impact Investment Calculator</Navbar.Brand>
                </Container>
            </Navbar>
            <Alert variant="success" style={{margin: '10px'}}>*The following information is for conversational purposes only. Make sure you always consult with your financial advisor before implementing any of the items discussed below.</Alert>
            <div className="calc-container">

                <Card className="calc-card-2">
                    <Card.Header>Complete the information below to calculate:</Card.Header>
                    <Card.Body>

                        <table className="calculator-table">
                            <tr>
                                <td><Form.Label>Investment cost per tree:</Form.Label></td>
                                <td><h2><Badge bg="dark"> ${cost}.00 </Badge></h2></td>
                            </tr>
                            <tr>
                                <td> <Form.Label>Number of trees:</Form.Label></td>
                                <td><Form.Control type="number" placeholder="Number of Trees" value={trees} onChange={e => setTrees(e.target.value)} /></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>

                                    <Form.Label>Select tree type to calculate board feet at maturity:</Form.Label>


                                </td>
                            </tr>
                            <tr>

                                <td colSpan={2}>
                                    <Form.Select value={feet} onChange={e => setFeet(e.target.value)}>
                                        <option value={450}>Honduras Mahogany</option>
                                        <option value={500}>Cedar</option>
                                        <option value={100}>Ciricote</option>
                                    </Form.Select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>Average board feet per tree:</InputGroup.Text>
                                        <Form.Control value={feet} disabled />
                                    </InputGroup>
                                </td>
                            </tr>
                            <tr >
                                <td colSpan={2}>

                                    <Form.Label>*Enter the board foot value after you do some research.<br />See the <a href="https://azucenas.org/azucenas_white_paper.pdf#page=6" target="_blank">White Paper</a></Form.Label>
                                </td>
                            </tr>
                            <tr>
                                <td><Form.Label>*Price Per Board Foot:</Form.Label></td>
                                <td>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control type="number" value={price} onChange={e => setPrice(e.target.value)} />
                                    </InputGroup>
                                </td>
                            </tr>
                            {/* <tr>
                            <td><Form.Label>Total Initial Investment</Form.Label></td>
                            <td><Form.Label><h2><Badge bg="dark">${(trees * cost).toLocaleString('en', { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2, useGrouping: true })}</Badge></h2></Form.Label></td>
                        </tr>
                        <tr>
                            <td><Form.Label>Total Years of Growth</Form.Label></td>
                            <td><Form.Label><h2><Badge bg="dark">{years}</Badge></h2></Form.Label></td>
                        </tr>
                        <tr>
                            <td><Form.Label>Investment Value After 18 years</Form.Label></td>
                            <td><h2><Badge bg="success">${(trees * feet * price).toLocaleString('en', { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2, useGrouping: true })}</Badge></h2></td>
                        </tr>
                        <tr>
                            <td><Form.Label>ROI Over 18 years</Form.Label></td>
                            <td><h2><Badge bg="success"> {+(
                                (((trees * price * feet) * (1)) - (trees * cost)) / (trees * cost) * 100


                            ).toFixed(2)}%</Badge></h2></td>
                        </tr> */}
                        </table>
                    </Card.Body>
                </Card>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Card className="calc-card">
                        <Card.Header>Total Initial Investment</Card.Header>
                        <Card.Body>This cost covers <strong>18 years</strong> of tree cultivation, forest management, and onsite security. This is a one-time payment.</Card.Body>
                        <Card.Footer><h2><Badge bg="dark">${(trees * cost).toLocaleString('en', { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2, useGrouping: true })}</Badge></h2></Card.Footer>
                    </Card>
                    {/* <Card className="calc-card">
                    <Card.Header>Total Years of Growth</Card.Header>
                    <Card.Body>Our tropical hardwood trees grow to maturity in 18 years.</Card.Body>
                    <Card.Footer><h2><Badge bg="dark">{years} Years</Badge></h2></Card.Footer>
                </Card> */}
                    <Card className="calc-card">
                        <Card.Header>Estimated value after 18 years.</Card.Header>
                        <Card.Body>This total is calculated based off of the board feet per tree and the price per board feet you entered</Card.Body>
                        <Card.Footer><h2><Badge bg="success">${(trees * feet * price).toLocaleString('en', { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2, useGrouping: true })}</Badge></h2></Card.Footer>

                    </Card>
                    <Card className="calc-card">
                        <Card.Header>Return On Investment</Card.Header>
                        <Card.Body>The percentage of returns that your trees have the ability to generate.</Card.Body>

                        <Card.Footer><h2><Badge bg="success"> {+(
                            (((trees * price * feet) * (1)) - (trees * cost)) / (trees * cost) * 100


                        ).toFixed(2)}%</Badge></h2></Card.Footer>
                    </Card>
                    <Card className="chart-card">
                        <Card.Header>Catalogue Value Over 18 Years</Card.Header>
                        {price <= 0 ? <Card.Body>Chart will load after you enter the board feet price...</Card.Body>:   <Card.Body>
                        <Bar data={data} options={options}/>

                        </Card.Body>}
                      
                        
                    </Card>
                </div>

            </div>

        </div>
    )
}