import { Alert, Button, Card, ListGroup, ListGroupItem, Table } from 'react-bootstrap'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import './MyTreeTable.scss'
import 'react-circular-progressbar/dist/styles.css';
import { useState } from 'react';
import { useEffect } from 'react';
import Projections from '../../projections';
export default function MyTreeTable({ trees, pending, graphData, userInfo }) {
    const [totalMahogany, setTotalMahogany] = useState(3)
    const [totalCiricote, setTotalCiricote] = useState(4)
    const [totalCedar, setTotalCedar] = useState(1)
    const [totalTrees, setTotalTrees] = useState(8)
    const [sortedTrees, setSortedTrees] = useState([])
    const [totalValue, setTotalValue] = useState()


    useEffect(() => {
        calculateTotalTreeTypes()
        calculateTotalValue()

    }, [trees])

    const calculateTotalTreeTypes = () => {
        const types = []
        const sorted = []
        const getTypes = trees.map((tree, index) => {
            if (types.includes(tree.tree_type)) {
            }
            else {
                let typeFilter = trees.filter(e => e.tree_type === tree.tree_type)
                types.push(tree.tree_type)
                sorted.push(typeFilter)
                return
            }
        })
        Promise.all(getTypes).then(() => {

            setSortedTrees(sorted)
        })


    }
    const calculateTotalValue = () => {
        let totaledValue = 0
        for (let i = 0; i < trees.length; i++) {
            let newTotal = +totaledValue + +trees[i].current_value
            totaledValue = +newTotal
        }
        setTotalValue(totaledValue)

    }
    return (
        <div className="My-Tree-Table">
            <br />
            <h2>My Tree Portfolio</h2>
            <hr />
            <div className="chart-row">
                <Card id="adjust-width"><Card.Header>Estimated Total Value</Card.Header><Card.Body><h2 style={{ color: 'gray' }}>${totalValue === undefined ? 'N/A' : totalValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h2></Card.Body></Card>
                {sortedTrees.map((type, index) => (
                    <div key={index + 'item'} className="chart-item">
                        <div style={{ width: 80, height: 80 }}>
                            <CircularProgressbarWithChildren value={(type.length / trees.length) * 100}>

                                <strong>{((type.length / trees.length) * 100).toFixed(0)}%</strong>
                            </CircularProgressbarWithChildren>
                        </div>
                        <strong>{type[0].tree_type}</strong>
                    </div>
                ))}
            </div>
            <Projections info={graphData} />
            {pending.length > 0 ?
                <Alert>
                    <Alert.Heading>You Recently Purchased Trees On The Marketplace:</Alert.Heading>
                    {pending.map((item, index) => (
                        <div>
                            {JSON.parse(item.checkout_items).map((item_pending, index2) => (
                                <p>{item_pending.tree_type} {Math.floor(item_pending.months / 12).toFixed(0) + ' years ' + (item_pending.months % 12) + ' months old'} from vendor {item_pending.buyer} for ${item_pending.value}</p>
                            ))}

                        </div>
                    ))}
                    <hr />
                    <strong>Please allow for a few days and our admin will process the paper work and we will reach out to you to update your bill of sale.</strong>
                </Alert> : ''}
            <Table responsive striped bordered hover >
                <thead>

                    <tr>
                        <th>Tree ID</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Date Planted</th>
                        <th>Tree Age</th>
                        <th>Est. Value</th>

                    </tr>
                </thead>
                <tbody>
                    {trees.map((tree, index) => (
                        <tr key={index + 'tree'}>
                            <td>{tree.tree_id}</td>
                            <td>{tree.tree_type}</td>
                            <td>{tree.status}</td>
                            <td>{tree.date_planted}</td>
                            <td>{Math.floor(tree.months / 12) + ' years ' + tree.months % 12 + ' month(s)'}</td>
                            <td style={{ display: 'flex', justifyContent: 'space-between' }}><div>$</div>{(+tree.current_value).toFixed(2)}</td>

                        </tr>
                    ))}
                </tbody>
            </Table>



        </div>
    )
}