import { Table } from "react-bootstrap"

import './OwnerTreesTable.scss'

export default function OwnerTreesTable(trees) {

    return (
        <div className="owner-tree-table">
            <Table responsive striped bordered hover >
                <thead>
                    <tr>
                        <th>Tree ID</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Date Planted</th>
                        <th>Est. Value</th>
                 
                    </tr>
                </thead>
                <tbody>

                    {trees.trees.map((item, index) => (
                        <tr key={'tree' + { index }}>
                            <td>{item.tree_id}</td>
                            <td>{item.tree_type}</td>
                            <td>{item.status}</td>
                            <td>{item.date_planted}</td>
                            <td>${item.current_value}</td>
                        </tr>
                    ))}

                </tbody>
            </Table>
        </div>
    )
}