import { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap"
import { useHistory } from "react-router-dom"


const PlaceOrder = () => {
    const history = useHistory()
    const [selectedOption, setSelectedOption] = useState('option1');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    const handleNext = () => {
        if (selectedOption === 'option1') {
            history.push('/place-order/existing-inventory')
        } else {
            history.push('/place-order/new-trees')
        }
    }
    return (
        <div>
            <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Online Orders</div>
            <div className="paper-container">
                <div className="paper">

                    <strong>Please select what kind of order you will be placing:</strong>
                    <hr />
                    <Form>

                        <Form.Check // prettier-ignore
                            type={'radio'}

                            label={<p><strong>Existing inventory</strong> of Planted trees by year and quantity.  Discuss current inventory with your Ambassador including pricing.  With this option, you are buying established trees from the Las Azucenas farm.  Trees are currently established, online, monitored, inventoried, and geo-located.  </p>}
                            id="option1"
                            value="option1"
                            checked={selectedOption === 'option1'}
                            onChange={handleOptionChange}
                        />
                        <Form.Check // prettier-ignore
                            type={'radio'}

                            label={<p><strong>Newly Planted Trees.</strong>  With this option, you are buying land and making a reservation for us to plant and manage the trees.
                            </p>}
                            id="option2"
                            value="option2"
                            checked={selectedOption === 'option2'}
                            onChange={handleOptionChange}
                        />
                    </Form>
                    <div className='button-container'>
                        <Button onClick={() => handleNext()} size="lg">Continue</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlaceOrder