
import { useHistory } from 'react-router-dom'
import './AdminHome.scss'
import { Button, Card } from 'react-bootstrap'
import axios from 'axios'
import QuickReports from './quickReports'
export default function AdminHome() {
    const history = useHistory()

    return (
        <div className="admin-dash-content">
            <h2>Welcome to the Admin Portal</h2>
            <hr />
            <div className="admin-dash-row">

                <div className="Dash-Block" onClick={() => history.push('/owners')}>

                    <img src="/find-users.svg" width="75px" alt="owners" />
                    <strong>Tree Owners</strong>
                </div>
                <div className="Dash-Block" onClick={() => history.push('/documents')}>

                    <img src="/documents.svg" width="75px" alt="owners" />
                    <strong>Documents</strong>
                </div>
                <div className="Dash-Block" onClick={() => history.push('/onboarding')}>

                    <img src="/onboard.svg" width="75px" alt="owners" />
                    <strong>Onboarding</strong>
                </div>
                <div className="Dash-Block" onClick={() => history.push('/trees')}>

                    <img src="/trees.svg" width="75px" alt="owners" />
                    <strong>Tree Search</strong>
                </div>
                <div className="Dash-Block" onClick={() => history.push('/broker')}>

                    <img src="/trade.svg" width="75px" alt="broker" />
                    <strong>Broker</strong>
                </div>
                <div className="Dash-Block" onClick={() => history.push('/pricing')}>

                    <img src="/pricing.svg" width="75px" alt="pricing" />
                    <strong>Pricing</strong>
                </div>
            </div>
            <br />
            <QuickReports />
        </div >

    )
}