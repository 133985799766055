import { Alert, Table } from "react-bootstrap"


export default function CurrentListings({listedTrees}) {

    return(
        <div>
            <Alert variant="success">Current Listings Live on the Marketplace:</Alert>
           <Table  responsive striped bordered hover>
               <thead>
                   <tr>
                       <th>Tree ID</th>
                       <th>Tree Age </th>
                       <th>Date Listed</th>
                       <th>Listed Price</th>
                   </tr>
                   
               </thead>
               <tbody>
                  {listedTrees.map((tree, index)=>(
                      <tr key={index + 'tree'}>
                          <td>{tree.tree_id}</td>
                          <td>{Math.floor(tree.months /12).toFixed(0) + ' years '+ tree.months % 12 + ' month(s)'}</td>
                          <td>{new Date(tree.last_updated).toLocaleDateString().replaceAll('/', '-')}</td>
                          <td>${(+tree.current_value).toFixed(2)}</td>
                      </tr>
                  ))}
               </tbody>
           </Table>
        </div>
    )
}