
import './demo2.scss'
import { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { Alert, Button, Card, Form, Row, Col, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';



export default function OnBoardingDemo2() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [signature, setSignature] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [sign, setSign] = useState('')
    const [show, setShow] = useState(false);
    const pad = useRef()
    const history = useHistory()

    const test = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
        setTimeout(() => {
            setShow(true)
        }, 2000)


    }
    const handleSubmit = () => {
        let storage = {
            name: firstName + ' ' + lastName,
            address,
            city,
            state,
            phone,
            email
        }
        let data = {
            buyerFirstName: firstName,
            buyerLastName: lastName,
            buyerAddress: address,
            buyerCity: city,
            buyerState: state,
            buyerPhone: phone,
            buyerEmail: email,
            buyerSignature: signature
        }
        localStorage.setItem('documentData', JSON.stringify(storage))
        // axios.post(process.env.REACT_APP_API + '/demo/pdf', { data }).then(res => {
        //     console.log(res)
        // })
        const requestOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
            body: JSON.stringify({ data })
        };
        try {
            fetch(process.env.REACT_APP_API + '/demo/pdf', requestOptions)
                .then(response => console.log(response))
            handleClose()
        } catch (err) {
            console.log(err)
        }
    }
    const handleClose = () => {
        setShow(false)
    }

    return (
        <div className="onboarding-demo">
            <div className="paper-container">
                <div className="paper">
                    <Card>
                        <Card.Header>Please fill out these fields. Your information will populate in the document below where you can sign.</Card.Header>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control required value={firstName} onChange={e => setFirstName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control value={lastName} onChange={e => setLastName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control value={address} onChange={e => setAddress(e.target.value)} type='text' placeholder='Address'></Form.Control>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control value={city} onChange={e => setCity(e.target.value)} type='text' placeholder='City'></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>State</Form.Label>
                                        <Form.Control value={state} onChange={e => setState(e.target.value)} type='text' placeholder='State'></Form.Control>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control value={email} onChange={e => setEmail(e.target.value)} type='Email' placeholder='Email'></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control value={phone} onChange={e => setPhone(e.target.value)} type='phone' placeholder='phone'></Form.Control>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    <hr />
                    <Alert variant="success">Management Agreement</Alert>
                    <h3> County of Los Angeles</h3>
                    <h3 className='center'> State of California</h3>
                    <hr />
                    <h2>Management Services Agreement</h2>
                    <br />
                    <p>This Agreement is made effective as of the DATE:<span className="date-box">{(new Date()).toDateString()}</span>,
                        by and between <span className="input-box">{firstName} {lastName}</span>(“Buyer”), of (City and State) <span className='input-box'>{city}, {state}</span> and LOS
                        SANTOS CONSULTING, LLC (LOS SANTOS), of Los Angeles, California.</p>
                    <p>
                        In this Agreement, the party who is contracting to receive MANAGEMENT services shall be
                        referred to as "BUYER", and the party who will be providing the services shall be referred to as
                        "LOS SANTOS".
                    </p>
                    <p>
                        LOS SANTOS has a background in MANAGEMENT and is willing to provide services to
                        BUYER based on this background.
                    </p>
                    <p>
                        BUYER desires to have services provided by LOS SANTOS.
                        Therefore, the parties agree as follows:
                    </p>
                    <p>
                        <strong> DESCRIPTION OF SERVICES.</strong> Beginning on DATE listed above, LOS SANTOS will
                        provide the following management services (collectively, the "Services"):
                    </p>
                    <p>
                        Supervision of all employees or independent contractors employed by LOS SANTOS.
                        LOS SANTOS has retained Javier Garcia Esquivel, owner of Las Azucenas Ranch and his team.
                        All personnel will be employees or independent contractors of LOS SANTOS and not of
                        BUYER, and LOS SANTOS shall be responsible for all income and payroll tax withholding and
                        reporting; and
                    </p>
                    <p>
                        Additionally, LOS SANTOS's services will include:
                    </p>
                    <p>
                        Description of activities to be carried out
                    </p>
                    <p>
                        There are important activities to be carried out as part of the project aiming to produce mahogany
                        (Swietenia macrophylla King), cedar (Cedrella odorata L.) and ciricote (Cordia dodecandra Dc.)
                        trees of high economic value
                        <ul>
                            <li>
                                Activities for establishing a nursery
                            </li>
                            <ul>
                                <li>
                                    a. Collection, handling, and storage
                                </li>
                                <li>
                                    b. Establishment of the nursery and production systems
                                </li>
                                <li>
                                    c. Work in the nursery for the production of saplings
                                </li>
                                <li>
                                    d. Pre-germination treatments
                                </li>
                                <li>
                                    e. Sowing
                                </li>
                                <li>
                                    f. Transplanting, or separating seedlings once they have reached the desired height
                                </li>
                                <li>
                                    g. Use of shade
                                </li>
                                <li>
                                    h. Inoculation
                                </li>
                                <li>
                                    i. Weed control and fertilization
                                </li>
                            </ul>
                            <li>
                                Activities for establishing a plantation
                            </li>
                            <ul>
                                <li>
                                    a. Determine the location and characteristics of the site where planting will
                                    take place
                                </li>
                                <li>
                                    b. Practical activities for the maintenance of cedar, mahogany, and ciricote
                                    plantations
                                </li>
                                <li>
                                    c. Pest and disease control, silvicultural treatments
                                </li>
                                <li>
                                    d. Protection of plantations
                                </li>
                                <li>
                                    e. Marketing
                                    Removal and Sale of Tree(s)
                                    Remittance of funds after removal and sales fees and ordinary expenses not to exceed
                                    10% of gross sales.
                                </li>
                            </ul>
                        </ul>

                    </p>
                    <p>
                        LOS SANTOS agrees to devote its best effort to the performance of its management services.
                        The parties further agree LOS SANTOS will perform such other services as agreed upon by the
                        parties from time to time.
                    </p>
                    <p>
                        <strong>PERFORMANCE OF SERVICES</strong>. The manner in which the Services are to be performed and
                        the specific hours to be worked by LOS SANTOS shall be determined by LOS SANTOS.
                        BUYER will rely on LOS SANTOS to work as many hours as may be reasonably necessary to
                        fulfill LOS SANTOS's obligations under this Agreement.
                    </p>
                    <p>
                        <strong> PAYMENT. </strong> The purchase price as documented in the Bill of Sale agreement, between BUYER
                        and LOS SANTOS, includes a fee that covers the Services outlined in this document. This is a
                        one time, inclusive, fee for Services.
                    </p>
                    <p>
                        <strong>   TERM/TERMINATION.</strong>  This Agreement may be terminated by either party upon 90 days
                        written notice to the other party.
                    </p>
                    <p>
                        <strong>RELATIONSHIP OF PARTIES. </strong> It is understood by the parties that LOS SANTOS is an
                        independent contractor with respect to Buyer, and not an employee of Buyer. Buyer will not
                        provide fringe benefits, including health insurance benefits, paid vacation, or any other employee
                        benefit, for the benefit of LOS SANTOS.
                    </p>
                    <p>
                        <strong>DISCLOSURE.</strong>  LOS SANTOS is required to disclose any outside activities or interests,
                        including ownership or participation in the development of prior agreements, that conflict or may
                        conflict with the best interests of BUYER. Prompt disclosure is required under this paragraph if
                        the activity or interest is related, directly or indirectly, to:
                        - any activity that LOS SANTOS may be involved with on behalf of BUYER
                    </p>
                    <p>
                        <strong> EMPLOYEES.</strong>  LOS SANTOS's employees, if any, who perform services for BUYER under
                        this Agreement shall also be bound by the provisions of this Agreement.

                    </p>
                    <p>
                        <strong>INJURIES. </strong> LOS SANTOS acknowledges LOS SANTOS's obligation to obtain appropriate
                        insurance coverage for the benefit of LOS SANTOS (and LOS SANTOS's employees, if any).
                        LOS SANTOS waives any rights to recovery from BUYER for any injuries that LOS SANTOS
                        (and/or LOS SANTOS's employees) may sustain while performing services under this
                        Agreement and that are a result of the negligence of LOS SANTOS or LOS SANTOS's
                        employees.
                    </p>
                    <p>
                        <strong> INDEMNIFICATION.</strong>  LOS SANTOS agrees to indemnify and hold harmless BUYER from all
                        claims, losses, expenses, fees including attorney fees, costs, and judgments that may be asserted
                        against BUYER that result from the acts or omissions of LOS SANTOS, LOS SANTOS's
                        employees, if any, and LOS SANTOS's agents.
                    </p>
                    <p>
                        <strong> ASSIGNMENT. </strong> LOS SANTOS's obligations under this Agreement may not be assigned or
                        transferred to any other person, firm, or corporation without the prior written consent of
                        BUYER.
                    </p>
                    <p>
                        <strong> RETURN OF RECORDS.</strong>  Upon termination of this Agreement, LOS SANTOS shall deliver all
                        records, notes, data, memoranda, models of any nature that are in LOS SANTOS's possession or
                        under LOS SANTOS's control and that are BUYER’s property or relate to BUYER’s business.
                        NOTICES. All notices required or permitted under this Agreement shall be in writing and shall
                        be deemed delivered when delivered in person or deposited in the United States mail, postage
                        prepaid, addressed as follows:
                    </p>
                    <p>
                        IF for : <span className="input-box">{firstName} {lastName}</span>
                        <br />
                        BUYER
                        ADDRESS: <span className="input-box">{address} {city}, {state}</span>
                        <br />
                        EMAIL: <span className="input-box">{email}</span>
                        <br />
                        PHONE NUMBER: <span className="input-box">{phone}</span>
                    </p>
                    <p>
                        IF for LOS SANTOS:
                        <br />
                        <br />
                        LOS SANTOS CONSULTING LLC
                        <br />
                        Guillermo Alain Romero, Managing Member
                        <br />
                        251 S Lake Ave Suite 800, Pasadena, CA 91101
                        <br />
                        (626) 344-8691; alain@lossantos.co
                        <br />
                    </p>
                    <p>
                        Such address may be changed from time to time by either party by providing written notice to
                        the other in the manner set forth above.
                    </p>
                    <p>
                        <strong> ENTIRE AGREEMENT. </strong>  This Agreement contains the entire agreement of the parties and there
                        are no other promises or conditions in any other agreement whether oral or written. This
                        Agreement supersedes any prior written or oral agreements between the parties.
                    </p>
                    <p>
                        <strong> AMENDMENT. </strong> This Agreement may be modified or amended if the amendment is made in
                        writing and is signed by both parties.
                    </p>
                    <p>
                        <strong>SEVERABILITY. </strong> If any provision of this Agreement shall be held to be invalid or
                        unenforceable for any reason, the remaining provisions shall continue to be valid and
                        enforceable. If a court finds that any provision of this Agreement is invalid or unenforceable, but
                        that by limiting such provision it would become valid and enforceable, then such provision shall
                        be deemed to be written, construed, and enforced as so limited.
                    </p>
                    <p>
                        <strong>  WAIVER OF CONTRACTUAL RIGHT. </strong>  The failure of either party to enforce any provision
                        of this Agreement shall not be construed as a waiver or limitation of that party's right to
                        subsequently enforce and compel strict compliance with every provision of this Agreement.
                    </p>
                    <p>
                        <strong> APPLICABLE LAW. </strong>  This Agreement shall be governed by the laws of the State of California.
                    </p>
                    <p>
                        <strong> SIGNATORIES. </strong> This Agreement shall be executed on the behalf of BUYER OF TREE(S) and
                        on behalf of LOS SANTOS CONSULTING LLC by GUILLERMO ALAIN ROMERO, its
                        Owner.
                    </p>
                    <Alert>
                        <Alert.Heading>Party receiving Services:</Alert.Heading>
                        <hr />
                        <p>

                        </p>
                        <p>
                            BUYER Signature

                        </p>
                        <p>
                            By:
                        </p>
                        {sign === '' ? <Button size="lg" variant="secondary" onClick={() => setSign('open')}>Click To Sign</Button> : signature === '' ?
                            <>
                                <Alert variant='secondary'>Draw your signature then press 'Accept Signature'</Alert>
                                <div className='signature-block'>
                                    < SignatureCanvas penColor='Blue'
                                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                        ref={pad}
                                    />
                                </div>
                                <Button onClick={() => test()}>Accept Signature</Button>
                            </>
                            :
                            <img src={signature} />

                        }
                        <br />
                        <br />
                        <p>
                            Date: {(new Date()).toLocaleDateString()}
                        </p>
                        <p>
                            Buyer’s Printed Name: <span className='input-box'>{firstName} {lastName}</span>

                        </p>

                    </Alert>
                    <Alert variant="secondary">
                        <Alert.Heading>Party providing services:</Alert.Heading>
                        <hr />
                        Los Santos will sign their portion separately.
                        <br />
                        You will receive a final copy after it is complete.
                        <br />
                        <br />
                        <p>
                            By: ___________________________________ Date: __________________
                            <br />
                            Los Santos Consulting, LLC<br />
                            Guillermo Alain Romero
                        </p>
                    </Alert>

                    <div className='button-container'>
                        <Button size="lg" onClick={() => setShow(true)}>Complete Document</Button>
                    </div>
                    <Modal show={show} onHide={() => handleClose()}>
                        <Modal.Header closeButton>
                            <Modal.Title>Finalize Document</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>You have completed the required fields. Ready to submit?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => handleSubmit()}>
                                Submit Document
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div >
    )
}