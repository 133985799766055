import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import axios from "axios";
import ClientDash from "./clientDash";
import NewClientWindow from "./newClientWindow";
import AdminDash from "./adminDash";
import Admin2Dash from "./admin2Dash";
Session.addAxiosInterceptors(axios);

export default function Home({ setShowHeader }) {
  const [role, setRole] = useState('loading')
  const history = useHistory()
  let { doesSessionExist, accessTokenPayload } = useSessionContext();
  useEffect(() => {

    if (doesSessionExist) {
      setRole(accessTokenPayload.role)
      setShowHeader(false)
    } else {
      setShowHeader(true)
      history.push('/auth')
    }


  }, [])
  const logout = async () => {

    await signOut()
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location.reload()
    return false

  }

  if (role === 'new') {
    return (
      <div><NewClientWindow setRole={setRole} logout={logout} /></div>

    )
  }
  if (role === 'client') {
    return (
      <ClientDash logout={logout} />
    )
  }
  if (role === 'admin') {
    return (
      <AdminDash logout={logout} />
    )
  }
  if (role === 'admin2') {
    return (

      <Admin2Dash logout={logout} />
    )
  }
  if (role === 'loading') {
    return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWith: '100vw', minHeight: '100vh' }}>

    </div>)
  }
  else {

    return (<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minWith: '100vw', minHeight: '100vh' }}>

      <h2 onClick={logout}>Sorry we're having technical difficulties. Please check back later.</h2>
      {/* <Spinner animation="border" variant="success" size="lg" /> */}
    </div>)
  }

}