import axios from "axios";
import { useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2";


const PlaceOrderNewTrees = () => {
    const history = useHistory()
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        offerNumber: 0,
        numberOfAcres: 0,
        numberOfTrees: 0,
        sectorFourTrees: 0,
        additionalComments: '',
        orderType: 'new trees',
        date: new Date().toLocaleDateString()
    });
    const [orderPlaced, setOrderPlaced] = useState(false);
    const [loading, setLoading] = useState(false);

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // You can perform additional actions here with the form data
        const requestOptions = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
            body: JSON.stringify({ data: formData })
        };
        try {
            setLoading(true)
            fetch(process.env.REACT_APP_API + '/public/order-existing', requestOptions)
                .then((response => response.json())).then(res => {
                    window.scrollTo(0, 0);
                    setLoading(false)
                    if (res.message === 'success') {

                        setOrderPlaced(true)
                        Swal.fire({ icon: 'success', title: 'Order Submitted' }).then(() => {

                        })

                    } else {
                        Swal.fire({ icon: 'error', title: 'Sorry we are having technical issues, please reach out to info@azucenas.org' })
                    }
                })
        } catch {
            setLoading(false)
            Swal.fire({ icon: 'error', title: 'Sorry we are having technical issues, please reach out to info@azucenas.org' })
        }
    };

    return (
        <div>
            <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Online Orders</div>
            <div className="paper-container">
                <div className="paper">
                    {loading && <Spinner animation="border" variant="success" size="xl"></Spinner>}
                    {!loading && orderPlaced && <Alert>
                        <Alert.Heading>Order Placed</Alert.Heading><p>An Email was sent to <strong>{formData.email}</strong> containing your order. We will be in contact with you soon.</p></Alert>}
                    {!loading && !orderPlaced && <Form onSubmit={handleSubmit}>
                        <h2>Newly Planted Trees Order Form</h2>
                        <p>With this option, you are buying land and making a reservation for us to plant and manage the trees.</p>
                        <hr />
                        <Row>

                            <Col lg={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your first name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your last name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg={6}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter your email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                            </Col>
                            <Col lg={6}>

                                <Form.Group controlId="phoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter your phone number"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <br />
                        <em>Please specify which offer # you are reserving from the <a href='https://azucenas.org/LasAzucenasTimberOptions.pdf' target="_blank">purchase options</a> sheet.
                            <br />
                            <strong>If your reservation is outside of the current options presented, please skip this portion of the form and put your order details in the additional comments field.</strong>
                        </em>
                        <br />
                        <br />
                        <Row>
                            <Col lg={12}>
                                <Form.Group controlId="offerNumber">
                                    <Form.Label>I would like to make a reservation for offer #</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of trees"
                                        name="offerNumber"
                                        value={formData.offerNumber}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="numberOfAcres">
                                    <Form.Label>Number of <strong>acres</strong> I am buying:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of acres"
                                        name="numberOfAcres"
                                        value={formData.numberOfAcres}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />
                                <Form.Group controlId="numberOfTrees">
                                    <Form.Label>Number of <strong>trees</strong> to be planted on my property:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of trees"
                                        name="numberOfTrees"
                                        value={formData.numberOfTrees}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <br />

                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Form.Group controlId="additionalComments">
                                    <Form.Label>Additional Comments</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Optional: Enter additional comments"
                                        name="additionalComments"
                                        value={formData.additionalComments}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Button variant="secondary" size="lg" style={{ marginRight: '1rem' }} onClick={() => history.push('/place-order')}>Cancel</Button>
                        <Button variant="success" size="lg" type="submit">
                            Submit Order
                        </Button>
                    </Form>}
                </div>
            </div>
        </div>
    )
}

export default PlaceOrderNewTrees