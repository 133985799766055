
import './demo.scss'
import { useEffect } from 'react';
import { useState } from 'react'
import { Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
export default function OnBoardingDemo() {
    const history = useHistory()
    return (
        <div className="onboarding-demo">
            <div className="paper-container">
                <div className="paper">
                    <Alert variant="primary">Welcome to the Las Azucenas Onboarding Process</Alert>
                    <strong>The onboarding process will go as follows:</strong>
                    <hr />
                    <ul>
                        <li><strong>Signing the management agreement.</strong></li>
                        <ul>
                            <li>This document will outline the process of cultivating your tropical hardwood trees.</li>
                            <li>The agreement will will also explain how inventory of your trees will be managed.</li>
                        </ul>
                        <li><strong>Completing your portion of the Bill of Sale</strong></li>
                        <ul>
                            <li>This document will list the id's of the trees you will purchasing.</li>
                        </ul>
                        <li><strong>After completing these documents you will be given instructions on how to make payment. The agreements are valid once payment has been made.</strong></li>
                        <ul>
                            <li>After payment is received we will complete the Bill of Sale and send you your copy.</li>
                        </ul>
                    </ul>
                    <div className='button-container'>
                        <Button onClick={() => history.push('/demo-onboarding2')} size="sm">Continue</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}