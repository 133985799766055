import { GoogleMap, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api"
import React from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";


const MapDemo = () => {
    const [map, setMap] = React.useState(null)
    const [markers, setMarkers] = React.useState([])
    const containerStyle = {
        width: '100%',
        minHeight: '80vh',
        height: '100%'
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAP_KEY
    })
    const center = {
        lat: 16.361409715739246,
        lng: -89.53180325702975
    };
    const propertyBoundaries = [
        [16.363069810690497, -89.53561909220527],
        [16.3625725696632, -89.53542187958752],
        [16.362726052229636, -89.53482695485732],
        [16.363096091893482, -89.53507347062951]

    ].map(e => {
        let obj = { lat: e[0], lng: e[1] }
        return obj
    });
    console.log(propertyBoundaries)
    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])
    const handleCalculate = (start, end, treeNumber) => {
        const startingPoint = start.split(',').map(Number); // San Francisco, CA
        const endPoint = end.split(',').map(Number); // Los Angeles, CA
        const totalTrees = treeNumber;
        console.log(startingPoint, endPoint, totalTrees)
        function calculateTreeCoordinates(startingPoint, endPoint, totalTrees) {
            const treeCoordinates = [];

            // Extracting latitude and longitude from the starting and ending points
            const startLat = startingPoint[0];
            const startLng = startingPoint[1];
            const endLat = endPoint[0];
            const endLng = endPoint[1];

            // Calculate the step size for latitude and longitude
            const latStep = (endLat - startLat) / (totalTrees - 1);
            const lngStep = (endLng - startLng) / (totalTrees - 1);

            // Calculate coordinates for each tree, including starting and ending points
            for (let i = 0; i < totalTrees; i++) {
                const treeLat = startLat + i * latStep;
                const treeLng = startLng + i * lngStep;
                treeCoordinates.push([treeLat, treeLng]);
            }

            return treeCoordinates;
        }
        const treeCoordinates = calculateTreeCoordinates(startingPoint, endPoint, totalTrees);
        setMarkers(treeCoordinates)
    }

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
        let treeNumber = document.getElementById('treeNumber').value
        let start = document.getElementById('start').value
        let end = document.getElementById('end').value
        console.log({
            treeNumber, start, end
        })
        handleCalculate(start, end, treeNumber)
    };

    return (
        <div className="admin-dash-content">
            <h2>Tree Marking Demo</h2>
            <br/>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col lg={4}>
                        <Form.Group>
                            <Form.Label>Number of Trees</Form.Label>
                            <Form.Control id='treeNumber' name='startingPoint' type="text" />
                        </Form.Group>
                        <br />
                    </Col>
                    <Col lg={4}>
                        <Form.Group>
                            <Form.Label>Starting Point</Form.Label>
                            <Form.Control id='start' name='startingPoint' type="text" />
                        </Form.Group>
                        <br />
                    </Col>
                    <Col lg={4}>

                        <Form.Group>
                            <Form.Label>Ending Point</Form.Label>
                            <Form.Control id='end' name='endingPoint' type="text" displayName="test" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>

                        <Button type='submit'>Create GPS Plots</Button>
                    </Col>
                </Row>
                <br />
            </Form>
            <div style={{ width: '100%' }}>
                <Row>

                    <Col lg={4}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Tree ID</th>
                                    <th>Location</th>
                                </tr>
                            </thead>
                            <tbody>
                                {markers.map((e, index) => (
                                    <tr>
                                        <td>S1-L1A{index + 1}</td>
                                        <td>{e[0] + ', ' + e[1]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        {isLoaded && <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={16}
                            options={{ maxZoom: 22, mapTypeId: 'satellite' }}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        >{
                                markers.map((e, index) => (
                                    <>
                                        {console.log(e[0])}
                                        <Marker

                                            key={index}
                                            icon={'tree-icon-map.svg'}
                                            position={{ lat: e[0], lng: e[1] }}
                                            onClick={() => console.log(e)}


                                        />
                                    </>
                                ))
                            }
                            { /* Child components, such as markers, info windows, etc. */}
                            <></>
                            <Polygon
                                paths={propertyBoundaries}
                                strokeColor="#0000FF" // Color of the border
                                strokeOpacity={0.8} // Opacity of the border
                                strokeWeight={2} // Thickness of the border
                                fillColor="#0000FF" // Fill color of the polygon
                                fillOpacity={0.35} // Opacity of the fill
                            />
                        </GoogleMap>}
                    </Col>
                </Row>

            </div>
        </div>
    )
}

export default MapDemo