import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import { Accordion, Alert, Badge, Button, Card, Col, ListGroup, Row, Spinner } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import convertDoctype from "../../../../utilities/convertDocType"
import makeCurrency from "../../../../utilities/currency"
import formatTimestamp from "../../../../utilities/formatTimestamp"


export default function ManageDocument() {
    const { document_id, type } = useParams()
    const [documentData, setDocumentData] = useState({})
    const [trees, setTrees] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            getDocument()
        }, 1500)
    }, [])

    const getDocument = () => {
        try {
            axios.get(process.env.REACT_APP_API + `/admin/document/${document_id}/${type}`).then(res => {
                setDocumentData(res.data.data[0])
                if (!res.data.data[0]) {
                    setError(true)
                    setErrorMessage('Document not found.')
                }
                setTrees(res.data.trees)
                setLoading(false)
            })
        } catch {
            setLoading(false)
            setError(true)
        }
    }

    const generateDocument = () => {
        axios.get(process.env.REACT_APP_API + '/generate/' + documentData.type + '/' + document_id).then(res => {

        })
    }


    const downloadDocument = () => {
        axios({
            url: process.env.REACT_APP_API + '/storage/pdf/' + document_id, //your url
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const href = URL.createObjectURL(response.data);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Document.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
    }

    const returnTotal = () => {
        if (documentData.custom_total === '0.00' || documentData.custom_total == null) {
            return makeCurrency(documentData.default_total)
        } else {
            return makeCurrency(documentData.custom_total)
        }
    }

    return (
        <div className="admin-dash-content">
            {loading ? <Spinner animation="border" variant="success" ></Spinner> : error ? <Alert variant="warning">Something went wrong. {errorMessage}</Alert> :
                <Col>
                    <Row>
                        <Col>
                            {documentData.party_one_data && !documentData.party_two_data ?
                                <Alert>
                                    <Alert.Heading>
                                        This Document is ready for Admin to sign and complete.
                                    </Alert.Heading>
                                    <Button onClick={() => history.push(`/sign/${documentData.type}/${document_id}`)}>Sign</Button>


                                </Alert> : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>Document Info</Card.Header>
                                <Card.Body>
                                    <ListGroup>
                                        {!documentData.owner_id ? <ListGroup.Item><Badge bg="success">This Document Is For a New Client</Badge></ListGroup.Item> : ''}
                                        <ListGroup.Item><h2><Badge>{convertDoctype(documentData.type)}</Badge></h2></ListGroup.Item>
                                        {documentData.type === 'bos' ? <ListGroup.Item>{returnTotal()}</ListGroup.Item> : ''}
                                        <ListGroup.Item>{documentData.owner_id ? documentData.first_name + ' ' + documentData.last_name : documentData.document_name}</ListGroup.Item>
                                        <ListGroup.Item>Document ID: <br /> {documentData.document_id}</ListGroup.Item>
                                        {documentData.owner_id ? <ListGroup.Item>Client Document Link: <br />https://azucenas.net/{documentData.type === 'mgmt' ? 'management-agreement' : documentData.type}/{documentData.document_id}</ListGroup.Item> : ''}
                                    </ListGroup>
                                    <br />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Header>Signing Info</Card.Header>
                                <Card.Body>
                                    <ListGroup>
                                        <ListGroup.Item active>Client Signature</ListGroup.Item>
                                        {documentData.party_one_data ? <ListGroup.Item><img src={JSON.parse(documentData.party_one_data).data.buyerSignature} width="100px" /><br />{formatTimestamp(JSON.parse(documentData.party_one_data).buyerSignedDate)}</ListGroup.Item> : <ListGroup.Item><Badge bg="danger">Not Signed</Badge></ListGroup.Item>}
                                        <ListGroup.Item active>Admin Signature</ListGroup.Item>
                                        {documentData.party_two_data ? <ListGroup.Item><img src={JSON.parse(documentData.party_two_data).sellerSignature} width="100px" /> <br />{formatTimestamp(JSON.parse(documentData.party_two_data).sellerSignedDate)}</ListGroup.Item> : <ListGroup.Item><Badge bg="danger">Not Signed</Badge></ListGroup.Item>}
                                    </ListGroup>
                                    <hr />
                                    {documentData.party_one_data && documentData.party_two_data ? <Button onClick={() => downloadDocument()}>Download Document</Button> : ''}

                                </Card.Body>
                            </Card>
                            <br></br>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            {documentData.type === 'bos' ?
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>{trees.length} tree(s)</Accordion.Header>
                                        <Accordion.Body>
                                            {trees.map((item, index) => (
                                                <p>{item.tree_id} | {item.tree_type}</p>
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion> : ''
                            }</Col>
                    </Row>
                </Col>
            }
        </div>
    )
}