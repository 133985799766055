import { Alert, Spinner } from "react-bootstrap";


export default function LoadingPage({ title, text }) {
    return (
        <div className='onboarding-demo'>
            <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Document System</div>
            <div className="paper-container">
                <div className="paper">
                    <div style={{ minWidth: '300px' }}>

                        {title ? <div>
                            <Alert>{title}</Alert>
                            <hr />
                        </div>
                            : ''}
                        {text ? text : ''}
                        <Spinner animation="border" variant="success" size="xl"></Spinner>
                    </div>
                </div>
            </div>
        </div>
    )
}