import axios from "axios";
import { Button, ListGroup, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
export default function Profile({ userInfo, loading }) {
    const history = useHistory()

    const resetPassword = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You will be logged out, and an email will be sent to ${userInfo.email} with password reset instructions.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Reset My Password',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                try {

                    signOut()
                    document.cookie.split(";").forEach((c) => {
                        document.cookie = c
                            .replace(/^ +/, "")
                            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });
                
                    axios.post(process.env.REACT_APP_API + '/auth/user/password/reset/token', {
                        formFields: [
                            {
                                "id": "email",
                                "value": userInfo.email
                            }
                        ]
                    }).then(() => {
                        Swal.fire({ icon: 'success', title: 'An email with password reset instructions was sent to ' + userInfo.email, timer: 3000 }).then(() => {
                            window.location.reload()
                        })

                    })

                } catch {
                    Swal.fire('Your request did not go through. Please contact support.')
                }
            } else {
                return
            }

        })
    }

    return (
        <div className="client-dash-content">

            {loading ? 'loading...'
                :
                <>
                    <ListGroup>
                        <ListGroup.Item>
                            Name: <strong>{userInfo.first_name} {userInfo.last_name}</strong>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            Email: <strong>{userInfo.email}</strong>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Phone: <strong>{userInfo.phone}</strong>
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                    Bill of Sale: <strong>{userInfo.bill_of_sale}</strong>
                </ListGroup.Item> */}
                        <ListGroup.Item>
                            OwnerID: <strong>{userInfo.owner_id}</strong>
                        </ListGroup.Item>
                    </ListGroup>
                    <hr />
                    <ListGroup>
                        <ListGroup.Item>
                            <Button onClick={() => resetPassword()}>Reset Password</Button>
                        </ListGroup.Item>
                    </ListGroup>
                </>
            }

        </div>
    )
}