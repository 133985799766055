import { useEffect } from 'react';
import '../ambassador.scss';
import { useState } from 'react';
import { Button, Accordion, Card } from 'react-bootstrap';


const AmbassadorContent = () => {
    const [fileData, setFileData] = useState([])
    useEffect(() => {
        getFiles()
    }, [])
    const getFiles = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
        };
        try {
            fetch(process.env.REACT_APP_API + '/public/files', requestOptions)
                .then((response => response.json())).then((res) => {
                    setFileData(res)
                })
        } catch (err) {

            console.log(err)
        }
    }
    return (
        <div className='ambassador'>
            <div className="document-page-bar"><img src="/logo.svg" /> Ambassador Material</div>
            <div className='page'>
                <div className='page-content'>
                    <Accordion defaultActiveKey="0">
                        {fileData.subjects?.map((subject, index) => (
                            <Accordion.Item eventKey={index} key={index + 'item'}>
                                <Accordion.Header>{subject}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='card-row'>

                                        {fileData.values.filter(e => e.subject === subject).map((fileItem, index) => (
                                            <Card>
                                                <Card.Header><strong>{fileItem.fileName}</strong></Card.Header>
                                                <Card.Body>{fileItem.description}</Card.Body>
                                                <Card.Footer><a href={fileItem.link} target='_blank' rel='noreferrer'><Button>View</Button></a></Card.Footer>
                                            </Card>
                                        ))}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}


                    </Accordion>
                </div>
            </div>

        </div>
    )
}
export default AmbassadorContent