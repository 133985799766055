import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { Alert, Badge, Card, Col, Row, Spinner } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import './processing.scss'

export default function ProcessingDocument({ type }) {
    const { document_id } = useParams()
    const [currentAction, setCurrentAction] = useState('pdf')
    const [pdfBuilt, setPdfComplete] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [tressTransferred, setTreesTransferred] = useState(false)
    const history = useHistory()

    useEffect(() => {
        generateDocument()
    }, [])

    const generateDocument = async () => {

        await axios.get(process.env.REACT_APP_API + '/generate/' + type + '/' + document_id).then(res => {
            if (res.data.message === 'success') {
                setPdfComplete(true)
                setCurrentAction('email')
                setTimeout(() => {
                    axios.get(process.env.REACT_APP_API + '/admin/email/document/' + document_id + '/' + type).then(res => {
                        if (res.data.message === 'success') {
                            setEmailSent(true)
                            if(type === 'bos'){
                                setCurrentAction('transfer')
                                axios.get(process.env.REACT_APP_API + '/admin/transfer-trees-bos/' + document_id).then(res =>{
                                    if(res.data.message === 'success'){
                                        setTreesTransferred(true)
                                        setCurrentAction('complete')
                                        Swal.fire({ icon: 'success', text: 'Your document was processed and emailed to each party. You will now be redirected back to the document management page.' }).then(() => {
                                            // history.push('/document/' + document_id + '/' + type)
                                        })
                                    } else {
                                        setCurrentAction('failed')
                                        alert('Something went wrong, please contact Taylor for help.')
                                        history.push('/document/' + document_id + '/' + type)
                                    }
                                })
                            } else {
                                setCurrentAction('complete')
                                Swal.fire({ icon: 'success', text: 'Your document was processed and emailed to each party. You will now be redirected back to the document management page.' }).then(() => {
                                    history.push('/document/' + document_id + '/' + type)
                                })
                            }
                        } else {
                            setCurrentAction('failed')
                            alert('Something went wrong, please contact Taylor for help.')
                            history.push('/document/' + document_id + '/' + type)
                        }
                    })
                }, 1000)
            } else {
                alert('Something went wrong, please contact Taylor for help.')
                setCurrentAction('failed')
                history.push('/document/' + document_id + '/' + type)
            }
        })

    }

    return (
        <div>
            <Alert>{currentAction === 'complete' ? 'Document Successfully Processed and Emailed.' : 'Document Processing.'}</Alert>
            <Col>
                <Row>
                    <Col>

                        <Card bg={!pdfBuilt ? 'warning' : 'success'} text='white'>
                            <Card.Header>PDF Compiled</Card.Header>
                            <Card.Body style={{ display: 'flex', justifyContent: 'center' }}>
                                {currentAction === 'pdf' ? <Spinner animation="border" variant="light" ></Spinner> : pdfBuilt ? <h2><Badge>Complete</Badge></h2> : <h2><Badge bg="danger">FAILED</Badge></h2>}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card bg={!emailSent ? 'warning' : 'success'} text='white'>
                            <Card.Header>Emails Sent</Card.Header>
                            <Card.Body style={{ display: 'flex', justifyContent: 'center' }}>
                                {currentAction === 'pdf' ? <p>Email will be sent after PDF is compiled.</p> : currentAction === 'email' ? <Spinner animation="border" variant="light" ></Spinner> : emailSent ? <h2><Badge>Complete</Badge></h2> : <h2><Badge bg="danger">FAILED</Badge></h2>}
                            </Card.Body>
                        </Card></Col>
                    <Col>
                        {type === 'bos' ?

                            <Card bg={!tressTransferred ? 'warning' : 'success'} text='white'>
                                <Card.Header>Trees Transferred</Card.Header>
                                <Card.Body style={{ display: 'flex', justifyContent: 'center' }}>
                                  {currentAction === 'pdf' || currentAction === 'email' ? <p>Tress will Transfer after Email is sent</p> : currentAction === 'transfer' ? <Spinner animation="border" variant="light" ></Spinner> : tressTransferred ?  <h2><Badge>Complete</Badge></h2> : <h2><Badge bg="danger">FAILED</Badge></h2>}
                                </Card.Body>
                            </Card>
                            : ''
                        }
                    </Col>
                </Row>
            </Col>

        </div>
    )
}