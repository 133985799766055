import axios from 'axios'
import { useState } from 'react'
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap'
import './NewClientWindow.scss'
import Session from "supertokens-auth-react/recipe/session";
Session.addAxiosInterceptors(axios);

export default function NewClientWindow({ logout, setRole }) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [alertMessage, setAlertMessage] = useState('TEST')
    const [showAlert, setShowAlert] = useState(false)

    const handleSubmit = () => {
        let firstTest = firstName.replace(/\s/g,'')
        let lastTest = lastName.replace(/\s/g,'')
        if(firstTest.length < 1  || lastTest.length < 1){
            setAlertMessage('First and Last Name cannot be blank')
            setShowAlert(true)
        }
        else{
            try{

                axios.post(process.env.REACT_APP_API + '/user/update/contact', {firstName, lastName, phone}).then(res=>{
                    setRole('client')
                })
            }
            catch(error){
                alert('There was an error.')
            }
        }
    }

    return (
        <div className="new-client-window">
            <div className="Form-Box">
                <img src="/logo.svg" width="100px" alt="logo" />
                <h2>Las Azucenas</h2>
                <Alert variant='success'>
                    Thank you for signing up for Las Azucenas Impact Investments. To continue please submit the following information:
                </Alert>

                <FloatingLabel
                    controlId="floatingInput"
                    label="First Name"
                    className="mb-3"
                >
                    <Form.Control onChange={e => setFirstName(e.target.value)} size="lg" type="text" placeholder="First Name" />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name"
                    className="mb-3"
                >
                    <Form.Control onChange={e => setLastName(e.target.value)} size="lg" type="text" placeholder="Last Name" />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Phone"
                    className="mb-3"
                >
                    <Form.Control onChange={e => setPhone(e.target.value)} size="lg" type="text" placeholder="Last Name" />
                </FloatingLabel>


                <Alert show={showAlert} variant="danger">
                    {alertMessage}
                </Alert>
                <Button onClick={handleSubmit} variant="success" size="lg">Submit</Button>

            </div>
   
        </div>
    )
}