import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import '../Owner.scss'
import { Alert, Badge, Button, Card, Col, Form, ListGroup, ListGroupItem, Row, Spinner, FloatingLabel, Table } from "react-bootstrap";
import { Route, Switch, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
Session.addAxiosInterceptors(axios);

export default function AssignNew(userInfo) {
    const [newTrees, setNewTrees] = useState([])
    const [testTree, setTestTree] = useState('')
    const [treeType, setTreeType] = useState('')
    const [datePlanted, setDatePlanted] = useState('')
    const [loading, setLoading] = useState(false)


    const handleInput = (text) => {
        let noSpace = text.replace(/\s/g, '').toUpperCase()
        setTestTree(noSpace)
    }
    const handleRemove = (id) => {
        let array = newTrees
        array.splice(array.findIndex(function (i) {
            return i.tree_id === id;
        }), 1);
        setNewTrees([...array])
    }
    const submitTestTree = async () => {

        if (testTree === '') {
            alert('please enter a tree id')
        } else {
            if (newTrees.filter(e => e.tree_id === testTree).length > 0) {
                alert('ERROR: You already added Tree Id: ' + testTree + ' to the list.')
            }
            else {
                setLoading(true)
                try {

                    await axios.get(process.env.REACT_APP_API + '/admin/trees/' + testTree + '/test').then(res => {
                        setLoading(false)
                        if (res.data.length == 1) {
                            setNewTrees([...newTrees, { tree_id: testTree, tree_type: res.data[0].tree_type, owner_id: userInfo.userInfo.owner_id, date_planted: res.data[0].date_planted }])
                            setTestTree('')
                        }
                        else {
                            alert(res.data.message)
                        }
                    })
                } catch {
                    setLoading(false)
                    alert("The ID you entered was incorrect.")
                }
            }
        }
    }
    const handleSubmitTrees = async () => {
        newTrees.length === 0 ? alert('You must fill out the form to add trees') :
            await axios.post(process.env.REACT_APP_API + '/admin/trees', { trees: newTrees, owner_id: userInfo.owner_id }).then(res => {
                if (res.data === 'success') {
                    setNewTrees([])
                    alert('success')
                }
            })

    }
    return (
        <div>
            <hr />
            <Alert variant="secondary" >
                Only continue if you are adding a tree that has <strong>NOT</strong> been previously owned by anyone other than Los Santos or if you are correcting an error. If you are updating tree ownership because this account purchased a tree from someone else please visit the <Alert.Link>Brokering Tab</Alert.Link>
                <hr />
                The bill of sale should be completed or updated before assigning trees.
            </Alert>
            <Card style={{ marginTop: '1em' }}>
                <Card.Header>Assign new trees to this account:</Card.Header>
                <Card.Body>
                    <p>Enter the Tree ID, Date Planted, and select a tree type, then click Add To List.</p>
                    <div style={{ display: 'flex' }}>
                        <Form style={{ marginRight: '1em', paddingRight: '1em', borderRight: 'solid 1px' }}>
                            <Row className="g-2">
                                <Col xs="auto">
                                    <FloatingLabel controlId="floatingInputGrid" label="Enter Tree ID">
                                        <Form.Control onChange={e => handleInput(e.target.value)} value={testTree} type="text" placeholder="Tree Id" />
                                    </FloatingLabel>
                                </Col>
                                {/* <Col xs="auto">
                                    <FloatingLabel controlId="floatingInputGrid" label="Date Planted">
                                        <Form.Control onChange={e => setDatePlanted(e.target.value)} type="date" placeholder="Tree Id" />
                                    </FloatingLabel>
                                </Col> */}
                                {/* <Col xs="auto">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Tree Type">
                                        <Form.Select onChange={e => setTreeType(e.target.value)} aria-label="Default select example">
                                            <option>Select Tree Type</option>
                                            <option value="Mahogany">Mahogany</option>
                                            <option value="Cericote">Cericote</option>
                                            <option value="Cedar">Cedar</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col> */}

                            </Row>
                            <hr />
                            {loading ?
                                <Button variant="secondary" size="lg" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    Loading...
                                </Button>
                                :
                                <Button onClick={() => submitTestTree()} variant="secondary" size="lg">Add To List</Button>
                            }

                        </Form>
                        <div>

                            <Table style={{ width: '500px' }} striped bordered>
                                <thead>
                                    <tr>
                                        <th>Tree_ID</th>
                                        <th>Type</th>
                                        <th>Date Planted</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newTrees.map((item, index) => (
                                        <tr key={'tree' + index}>
                                            <td>{item.tree_id}</td>
                                            <td>{item.tree_type}</td>
                                            <td>{item.date_planted}</td>
                                            <td onClick={() => handleRemove(item.tree_id)} style={{ textAlign: 'center' }}><img width="20px" src="/redx.svg" /></td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {/* <hr /> */}
                            <Button onClick={() => handleSubmitTrees()} variant="success">Submit Changes</Button>
                        </div>
                    </div>
                </Card.Body>

            </Card>

        </div>
    )
}