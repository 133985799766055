import axios from 'axios'
import { useState, useEffect } from 'react'
import './TreeIndex.scss'

export default function TreeIndex() {
    return (
        <div>
            PDF page
        </div>
    )
}