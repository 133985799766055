import axios from "axios";
import { useEffect, useState } from "react"
import { Badge, Card, Col, ListGroup, Row, Spinner } from "react-bootstrap";


const Orders = () => {
    const [orders, setOrders] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getOrders()
    }, [])
    const getOrders = () => {
        axios.get(process.env.REACT_APP_API + '/admin/orders').then(res => {
            console.log(res)
            setOrders(res.data)
            setLoading(false)
        })
    }

    return (
        <div className='admin-dash-content'>
            <h2>Orders</h2>
            <hr />
            {loading && <Spinner />}
            <Row>
                {!loading && orders?.map((order, index) => (
                    <Col lg={6} md={4}>
                        <Card>
                            <Card.Header>{order.data.firstName} {order.data.lastName}</Card.Header>
                            <Card.Body>
                                <p>Date: {order.data.date}</p>
                                <p>Email: {order.data.email}</p>
                                <p>Phone: {order.data.phoneNumber}</p>
                                {order.data.offerNumber > 0 && <p>Offer Number: <Badge bg="success">{order.data.offerNumber}</Badge></p>}
                                {order.data.numberOfAcres > 0 && <p>Number of Acres <Badge bg="success">{order.data.numberOfAcres}</Badge></p>}
                                {order.data.numberOfTrees > 0 && <p>Number of Acres <Badge bg="success">{order.data.numberOfTrees}</Badge></p>}
                                {order.data.sectorOneTrees > 0 && <p>2018 Trees: <Badge bg="success">{order.data.sectorOneTrees}</Badge></p>}
                                {order.data.sectorTwoTrees > 0 && <p>2019 Trees: <Badge bg="success">{order.data.sectorTwoTrees}</Badge></p>}
                                {order.data.sectorThreeTrees > 0 && <p>2020 Trees: <Badge bg="success">{order.data.sectorThreeTrees}</Badge></p>}
                                {order.data.sectorFourTrees > 0 && <p>2021 Trees: <Badge bg="success">{order.data.sectorFourTrees}</Badge></p>}
                                {order.data.additionalComments.length > 0 && <ListGroup><ListGroup.Item variant="secondary">Additional Comments: {order.data.additionalComments}</ListGroup.Item></ListGroup>}
                            </Card.Body>
                        </Card>
                        <br />
                    </Col>
                ))}
            </Row>
        </div>
    )
}
export default Orders