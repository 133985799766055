import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import TreeIndex from "./treeIndex";
import Session from "supertokens-auth-react/recipe/session";
import axios from "axios";
import './pdf.scss';
Session.addAxiosInterceptors(axios);

export default function PDF() {
    const history = useHistory()
    let { doesSessionExist, accessTokenPayload } = useSessionContext();
    useEffect(() => {

        if (doesSessionExist) {

        } else {

            history.push('/auth')
        }


    }, [])
    return (
        <div className="pdf">
            <Switch>
                <Route path="/pdf/index">
                    <TreeIndex />
                </Route>
                <Route path="/pdf">
                    <p>Not Found</p>
                </Route>
            </Switch>
      
        </div>
    )
}