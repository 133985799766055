import { useParams } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { Alert, Button, Card, ListGroup, Table, Modal, Spinner, Dropdown, Form, Row, Col, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SignatureCanvas from 'react-signature-canvas'
import ErrorPage from "../../../../utilities/errorPage"
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';
import formatTimestamp from "../../../../utilities/formatTimestamp";
import axios from "axios";
import ProcessingDocument from "../processingPage";
import javier from './javier';
export default function AdminSignMGMT() {
    const [data, setData] = useState('')
    const [documentData, setDocumentData] = useState({})
    const [isComplete, setIsComplete] = useState(false)
    const [firstName, setFirstName] = useState('Alain')
    const [lastName, setLastName] = useState('Romero')
    const [sign, setSign] = useState('')
    const [show, setShow] = useState(false);
    const [signature, setSignature] = useState('')
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [sessionId, setSessionId] = useState(null)
    const [showDraw, setShowDraw] = useState(false)
    const [trees, setTrees] = useState([])
    const { document_id } = useParams()
    const [validInput, setValidInput] = useState(false)
    const [buyerData, setBuyerData] = useState()
    const [processingPDF, setProcessingPDF] = useState(false)

    const pad = useRef()
    const history = useHistory()


    useEffect(() => {
        window.scrollTo(0, 0)
        getDocumentData()
    }, [])

    useEffect(() => {
        if (signature !== '') {
            setShow(true)
        }
    }, [signature])

    const getDocumentData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
        };
        try {
            fetch(process.env.REACT_APP_API + '/document/' + document_id + '/mgmt', requestOptions)
                .then((response => response.json())).then((res) => {
                    if (res.data[0]) {
                        setDocumentData(res.data[0])
                        setBuyerData(JSON.parse(res.data[0].party_one_data))
                        setTrees(res.trees)
                        setLoading(false)
                        if (res.data[0].complete || res.data[0].party_two_data !== null) {
                            setIsComplete(true)
                            history.push('/documents')
                        }
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                })
        } catch (err) {
            setError(true)
            console.log(err)
        }
    }
    const returnTotal = () => {
        if (documentData.custom_total === '0.00' || documentData.custom_total == null) {
            return formatter.format(documentData.default_total)
        } else {
            return formatter.format(documentData.custom_total)
        }
    }
    const test = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
        setTimeout(() => {
            setShow(true)
        }, 2000)
    }
    const captureSig = () => {
        let signatureCapture = pad.current.toDataURL()
        setSign('complete');
        setSignature(signatureCapture)
    }

    const handleSubmit = () => {
        if (signature === '') {
            Swal.fire({ icon: 'info', title: 'Please sign the document before submitting.' })
            return
        }
        setLoading(true)
        let data = {
            sellerFirstName: firstName,
            sellerLastName: lastName,
            sellerSignature: signature,
            sellerUserAgent: navigator.userAgent,
            sellerTrees: trees
        }

        try {
            axios.post(process.env.REACT_APP_API + '/admin/sign/mgmt/' + document_id, data).then(res => {
                if (res.data.message === 'success') {
                    setProcessingPDF(true)
                    Swal.fire({ icon: 'success', title: 'Document Successfully Signed', timer: 1500 })
                } else {
                    Swal.fire({ icon: 'error', title: 'Something did not work correctly, please reach out to Taylor for help.' })
                }
            })

            handleClose()
        } catch (err) {
            console.log(err)
        }
    }
    const handleClose = () => {
        setShow(false)
    }

    const createTextSignature = (id) => {
        // create the element
        const div = document.createElement('div');
        div.innerHTML = firstName + ' ' + lastName;
        div.id = id ?? 'sig1'
        div.classList.add('create-signature')
        document.body.appendChild(div);
        // convert the element to a base64 image
        html2canvas(div, { scale: 2.5 }).then(canvas => {
            const base64Image = canvas.toDataURL();
            setSignature(base64Image);
        });
        document.body.removeChild(div);
    }
    const handleCheckInputs = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidInput(false)
        }
        event.preventDefault();
        setValidInput(true);
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (processingPDF ?
        <div className="admin-dash-content">
            <ProcessingDocument type='mgmt' />
        </div> :
        !isError ?
            <div className="onboarding-demo">
                <div className="paper-container">
                    {loading ?
                        <div className="paper" style={{ minWidth: '8.5in' }}>
                            <Spinner animation="border" variant="success" size="lg"></Spinner>
                            <h2>Processing...</h2>
                        </div> : <div className="paper">

                            <Alert variant="success">
                                <h2>Admin Document Signing:</h2>
                                <hr />
                                Confirm your name, then click submit.
                            </Alert>
                            <Form validated={validInput} onSubmit={handleCheckInputs}>
                                <Row>
                                    <Col sm>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control disabled={validInput} required value={firstName} onChange={e => setFirstName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                    </Col>
                                    <Col sm>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control disabled={validInput} required value={lastName} onChange={e => setLastName(e.target.value)} type='text' placeholder="First Name"></Form.Control>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>{!validInput ? <Button type="submit">Submit</Button> : ''}

                                    </Col>
                                </Row>
                            </Form>
                            <Row>
                                <Col>
                                    {validInput ? <Button variant="secondary" onClick={() => setValidInput(false)}>Edit First And Last Name</Button> : ''}
                                </Col>
                            </Row>
                            {
                                validInput ?
                                    <>
                                        <hr />
                                        <Alert variant="success">Management Agreement</Alert>
                                        <h3> County of Los Angeles</h3>
                                        <h3 className='center'> State of California</h3>
                                        <hr />
                                        <h2>Management Services Agreement</h2>
                                        <br />
                                        <p>This Agreement is made effective as of the DATE:<span className="date-box">{(new Date()).toDateString()}</span>,
                                            by and between <span className="input-box">{buyerData.data.buyerFirstName} {buyerData.data.buyerLastName}</span>(“BUYER”), of (City and State) <span className='input-box'>{buyerData.data.buyerCity}, {buyerData.data.buyerState}</span>
                                            and J&F Exportaciones Company (d/b/a Las Azucenas), registered in the Trade Register of the Republic of Guatemala, C.A. under registration number 104048, folio 116, book 96 of commercial companies.</p>
                                        <p>
                                            In this Agreement, the party who is contracting to receive MANAGEMENT services shall be referred to as "BUYER", and the party who will be providing the services shall be referred to as "Las Azucenas".
                                        </p>
                                        <p>
                                            Las Azucenas has a background in MANAGEMENT and is willing to provide services to BUYER based on this background.
                                        </p>
                                        <p>
                                            BUYER desires to have certain services provided by Las Azucenas.
                                        </p><p>
                                            Therefore, the parties agree as follows:
                                        </p>
                                        <p>
                                            <strong> DESCRIPTION OF SERVICES.</strong> Beginning on the DATE listed above, Las Azucenas will provide the following management services (collectively, the "Services"):
                                        </p>
                                        <p>
                                            Supervision of all employees or independent contractors employed by Las Azucenas. Las Azucenas has retained Javier Garcia Esquivel, owner of Las Azucenas Ranch, and his team.  All personnel will be employees or independent contractors of Las Azucenas and not of BUYER, and Las Azucenas shall be responsible for all payroll tax withholding and reporting; and
                                        </p>
                                        <p>
                                            Additionally, Las Azucenas's services will include:
                                        </p>
                                        <p>
                                            Description of activities to be carried out
                                        </p>
                                        <p>
                                            There are important activities to be carried out as part of the project aiming to produce mahogany (Swietenia macrophylla King), cedar (Cedrella odorata L.) and ciricote (Cordia dodecandra Dc.) trees of high economic value
                                            <ul>
                                                <li>
                                                    Activities for establishing a nursery
                                                </li>
                                                <ul>
                                                    <li>
                                                        a. Collection, handling, and storage
                                                    </li>
                                                    <li>
                                                        b. Establishment of the nursery and production systems
                                                    </li>
                                                    <li>
                                                        c. Work in the nursery for the production of saplings
                                                    </li>
                                                    <li>
                                                        d. Pre-germination treatments
                                                    </li>
                                                    <li>
                                                        e. Sowing
                                                    </li>
                                                    <li>
                                                        f. Transplanting, or separating seedlings once they have reached the desired height
                                                    </li>
                                                    <li>
                                                        g. Use of shade
                                                    </li>
                                                    <li>
                                                        h. Inoculation
                                                    </li>
                                                    <li>
                                                        i. Weed control and fertilization
                                                    </li>
                                                </ul>
                                                <li>
                                                    Activities for establishing a plantation
                                                </li>
                                                <ul>
                                                    <li>
                                                        a. Determine the location and characteristics of the site where planting will
                                                        take place
                                                    </li>
                                                    <li>
                                                        b. Practical activities for the maintenance of cedar, mahogany, and ciricote
                                                        plantations
                                                    </li>
                                                    <li>
                                                        c. Pest and disease control, silvicultural treatments
                                                    </li>
                                                    <li>
                                                        d. Protection of plantations
                                                    </li>
                                                    <li>
                                                        e. Marketing
                                                    </li>
                                                </ul>

                                                <li><strong>Removal and Sales of Trees(s)</strong> and ordinary expenses not to exceed 10% of gross sales.</li>
                                                <li>The <strong>Remittance of funds</strong> to the buyer, after removal and sale will remain the
                                                    responsibility of <strong>Los Santos Consulting, LLC.</strong></li>
                                            </ul>

                                        </p>
                                        <p>
                                            Las Azucenas agrees to devote its best effort to the performance of its management services. The parties further agree Las Azucenas will perform such other services as agreed upon by the parties from time to time.
                                        </p>
                                        <p>
                                            <strong>PERFORMANCE OF SERVICES</strong>. The manner in which the Services are to be performed and the specific hours to be worked by Las Azucenas shall be determined by Las Azucenas. BUYER will rely on Las Azucenas to work as many hours as may be reasonably necessary to fulfill Las Azucenas's obligations under this Agreement.
                                        </p>
                                        <p>
                                            <strong> PAYMENT. </strong> The purchase price as documented in the Bill of Sale agreement, between BUYER and Los Santos, includes a fee that covers the services outlined in this document.  This is a one-time, inclusive, fee for services.
                                        </p>
                                        <p>
                                            <strong>TERMINATION.</strong>  This Agreement may be terminated by either party upon 90 days written notice to the other party.
                                        </p>
                                        <p>
                                            <strong>RELATIONSHIP OF PARTIES. </strong>  It is understood by the parties that Las Azucenas is an independent contractor with respect to BUYER, and not an employee, partner or joint venturer of BUYER.  BUYER will not provide fringe benefits, including health insurance benefits, paid vacation, or any other employee benefit, for the benefit of Las Azucenas.

                                        </p>
                                        <p>
                                            <strong>DISCLOSURE.</strong>  Las Azucenas is required to disclose any outside activities or interests, including ownership or participation in the development of prior agreements, that conflict or may conflict with the best interests of BUYER.  Prompt disclosure is required under this paragraph if the activity or interest is related, directly or indirectly, to:
                                            <br />
                                            - any activity that Las Azucenas may be involved with on behalf of BUYER
                                        </p>
                                        <p>
                                            <strong> EMPLOYEES.</strong>  Las Azucenas's employees, if any, who perform services for BUYER under this Agreement shall also be bound by the provisions of this Agreement.


                                        </p>
                                        <p>
                                            <strong>INJURIES. </strong> Las Azucenas acknowledges Las Azucenas's obligation to obtain appropriate insurance coverage for the benefit of Las Azucenas (and Las Azucenas's employees, if any). Las Azucenas waives any rights to recovery from BUYER for any injuries that Las Azucenas (and/or Las Azucenas's employees) may sustain while performing services under this Agreement and that is a result of the negligence of Las Azucenas or Las Azucenas's employees.

                                        </p>
                                        <p>
                                            <strong> INDEMNIFICATION.</strong>  Las Azucenas agrees to indemnify and hold harmless BUYER from all claims, losses, expenses, and fees including attorney fees, costs, and judgments that may be asserted against BUYER that result from the acts or omissions of Las Azucenas, Las Azucenas's employees, if any, and Las Azucenas's agents.
                                        </p>
                                        <p>
                                            <strong> ASSIGNMENT. </strong> Las Azucenas's obligations under this Agreement may not be assigned or transferred to any other person, firm, or corporation without the prior written consent of BUYER.

                                        </p>
                                        <p>
                                            <strong> RETURN OF RECORDS.</strong>  Upon termination of this Agreement, Las Azucenas shall deliver to BUYER all records, notes, data, memoranda, and models of any nature that are in Las Azucenas's possession or under Las Azucenas's control and that are BUYER’s property or relate to BUYER’s business.
                                        </p>
                                        <p>
                                            <strong>NOTICES.</strong> All notices required or permitted under this Agreement shall be in writing and shall be deemed delivered when delivered in person or deposited in the United States mail, postage prepaid, addressed as follows:

                                        </p>
                                        <p>
                                            IF for : <span className="input-box">{buyerData.data.buyerFirstName} {buyerData.data.buyerLastName}</span>
                                            <br />
                                            BUYER
                                            ADDRESS: <span className="input-box">{buyerData.data.buyerAddress} {buyerData.data.buyerCity}, {buyerData.data.buyerState}</span>
                                            <br />
                                            EMAIL: <span className="input-box">{buyerData.data.buyerEmail}</span>
                                            <br />
                                            PHONE NUMBER: <span className="input-box">{buyerData.data.buyerPhone}</span>
                                        </p>
                                        <p>
                                            IF for Las Azucenas:
                                            <br />
                                            <br />
                                            Javier G Esquivel
                                            <br />
                                            <br />
                                            Personal Identification Document (“DPI”) number 1832796462201 and having a residential address in Ciudad Satélite, Santa Elena Flores Peten, Guatemala.

                                            <br />
                                            <br />
                                            Los Santos Consulting will remain the primary contact with the BUYER.
                                        </p>
                                        <p>
                                            Such address may be changed from time to time by either party by providing written notice to the other in the manner set forth above.
                                        </p>
                                        <p>
                                            <strong> ENTIRE AGREEMENT. </strong>  This Agreement may be modified or amended if the amendment is made in writing and is signed by both parties.

                                        </p>
                                        <p>
                                            <strong> AMENDMENT. </strong> This Agreement may be modified or amended if the amendment is made in
                                            writing and is signed by both parties.
                                        </p>
                                        <p>
                                            <strong>SEVERABILITY. </strong> If any provision of this Agreement shall be held to be invalid or unenforceable for any reason, the remaining provisions shall continue to be valid and enforceable. If a court finds that any provision of this Agreement is invalid or unenforceable, but that by limiting such provision it would become valid and enforceable, then such provision shall be deemed to be written, construed, and enforced as so limited.

                                        </p>
                                        <p>
                                            <strong> NO WAIVER OF CONTRACTUAL RIGHT. </strong>  The failure of either party to enforce any provision of this Agreement shall not be construed as a waiver or limitation of that party's right to subsequently enforce and compel strict compliance with every provision of this Agreement.

                                        </p>
                                        <p>
                                            <strong> APPLICABLE LAW. </strong>  This Agreement shall be governed by the laws of the State of California, USA.

                                        </p>
                                        <p>
                                            <strong> SIGNATORIES. </strong> This Agreement shall be executed on the behalf of BUYER OF TREE(S) and on behalf of Las Azucenas by Javier G Esquivel, its Owner.
                                        </p>


                                        <Form.Check // prettier-ignore
                                            type={'checkbox'}
                                            id={`acknowledge`}
                                            checked={true}
                                            disabled={true}
                                            label={<>I acknowledge that I have received and read the <a href='https://azucenas.org/azucenas_white_paper.pdf' target='_blank' rel='noreferrer'>Las Azucenas white paper</a>. This overview
                                                outlines key details about the Las Azucenas timber cultivation project, including:</>}

                                        />



                                        <ul>
                                            <li>Background on the company and management team</li>
                                            <li>Track record</li>
                                            <li>Location and species of trees being offered</li>
                                            <li>Growth cycles and estimated timeline to maturity</li>
                                            <li>Historical present and proforma tree prices</li>
                                            <li>Explanation of land lease arrangements</li>
                                            <li>Responsibilities for crop care and maintenance</li>
                                            <li>Commissions, fees, and potential investor exit options</li>
                                            <li>Risk factors such as diseases, pests, fire, weather events, and market fluctuations</li>
                                        </ul>

                                        <Alert variant="secondary">
                                            <Alert.Heading>Party receiving Services:</Alert.Heading>
                                            <hr />
                                            <p>

                                            </p>
                                            <p>
                                                BUYER Signature

                                            </p>
                                            <p>
                                                By:
                                            </p>
                                            <img src={buyerData.data.buyerSignature} style={{ maxWidth: '100%' }} /><br />


                                            <p>
                                                Date: {formatTimestamp(buyerData.buyerSignedDate)}
                                            </p>
                                            <p>
                                                Buyer’s Printed Name: <span className='input-box'>{buyerData.data.buyerFirstName + ' ' + buyerData.data.buyerLastName}</span>

                                            </p>

                                        </Alert>
                                        <br />
                                        <Alert variant="secondary">
                                            <Alert.Heading>Party providing services:</Alert.Heading>
                                            <hr />
                                            J&F Exportaciones Company (Las Azucenas)
                                            <br />
                                            <br />
                                            <p>
                                                <img src={javier} style={{ maxWidth: '100%' }} /><br />
                                                <p>Date: {formatTimestamp(new Date())}</p>
                                                <br />
                                                Javier G Esquivel<br />
                                            </p>

                                        </Alert>
                                        <Alert>
                                            <Alert.Heading>Managing sale proceeds and disbursements:</Alert.Heading>
                                            <hr />

                                            Los Santos Consulting, LLC
                                            <br />
                                            <p>
                                                By:</p>

                                            <br />
                                            {sign === '' ? <Button size="lg" onClick={() => setSign('open')}>Click To Sign</Button> : signature === '' ?
                                                <>
                                                    <Alert variant='secondary'>Select A Signature To Use From the Dropdown Menu
                                                        <hr />
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                Choose a Signature
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu onChange={e => console.log(e)}>
                                                                <Dropdown.Item id="sig1" onClick={() => createTextSignature('sig1')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                                <Dropdown.Item id="sig2" onClick={() => createTextSignature('sig2')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                                <Dropdown.Item id="sig3" onClick={() => createTextSignature('sig3')}>{firstName + ' ' + lastName}</Dropdown.Item>
                                                                <Dropdown.Item id="my-own" onClick={() => setShowDraw(true)}>Draw my own</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></Alert>

                                                    {
                                                        !showDraw ? '' :
                                                            <div>
                                                                <p>Draw your signature in the white space below, then click 'Accept Signature'</p>
                                                                <br />
                                                                <div className='signature-block'>
                                                                    <SignatureCanvas penColor='Blue'
                                                                        canvasProps={{ className: "sigCanvas" }}
                                                                        ref={pad}
                                                                    />
                                                                </div>
                                                                <Button onClick={() => captureSig()}>Accept Signature</Button>
                                                                <br />
                                                                <br />
                                                            </div>
                                                    }
                                                </>
                                                :
                                                <div>
                                                    <img src={signature} style={{ maxWidth: '100%' }} /><br />
                                                    <p style={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setSignature('')}>Click here to redo signature.</p>
                                                </div>

                                            }
                                            <br />

                                            <p>
                                                Guillermo Alain Romero
                                            </p>
                                        </Alert>
                                        <br />
                                        <div className='button-container'>
                                            <Button size="lg" onClick={() => setShow(true)}>Complete Document</Button>
                                        </div>
                                        <Modal show={show} onHide={() => handleClose()}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Finalize Document</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>You have completed the required fields. Ready to submit?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => handleClose()}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" onClick={() => handleSubmit()}>
                                                    Submit Document
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </> : <Alert variant="warning">Click submit on the form above and your document will load here.</Alert>
                            }

                        </div>}


                </div>
                <Modal show={show} onHide={() => handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Finalize Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You have completed the required fields. Ready to submit?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => handleClose()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => handleSubmit()}>
                            Submit Document
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> : <ErrorPage />
    )
}