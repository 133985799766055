import { useEffect, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import ErrorPage from "../../utilities/errorPage";


export default function StartOnboarding() {
    const { onboarding_id } = useParams()
    const history = useHistory()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false)

    useEffect(() => {
        getOnboardingData()
    }, [])
    const getOnboardingData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            credentials: 'omit',
        };
        try {
            fetch(process.env.REACT_APP_API + '/onboarding/' + onboarding_id, requestOptions)
                .then((response => response.json())).then((res) => {
                    if (res.data[0]) {
                        setData(res.data[0])
                        sessionStorage.setItem('mgmt', res.data[0].management_id)
                        sessionStorage.setItem('bos', res.data[0].bos_id)
                        setLoading(false)
                    } else {
                        setLoading(false)
                        setError(true)
                    }
                })
        } catch (err) {
            setError(true)
            console.log(err)
        }
    }

    return (
        isError ? <ErrorPage /> : loading ? <Spinner></Spinner> :
            <div className="onboarding-demo">
                <div className="document-page-bar"><img src="/lasazucenas.png" /> Las Azucenas Document System</div>
                <div className="paper-container">
                    <div className="paper">
                        <Alert variant="primary">{data.name}: Welcome to the Las Azucenas Onboarding Process</Alert>
                        <strong>The onboarding process will go as follows:</strong>
                        <hr />
                        <ul>
                            <li><strong>Signing the management agreement.</strong></li>
                            <ul>
                                <li>This document will outline the process of cultivating your tropical hardwood trees.</li>
                                <li>The agreement will will also explain how inventory of your trees will be managed.</li>
                            </ul>
                            <li><strong>Completing your portion of the Bill of Sale</strong></li>
                            <ul>
                                <li>This document will list the id's of the trees you will purchasing.</li>
                            </ul>
                            <li><strong>After completing these documents you will be given instructions on how to make payment. The agreements are valid once payment has been made.</strong></li>
                            <ul>
                                <li>After payment is received we will complete the Bill of Sale and send you your copy.</li>
                            </ul>
                        </ul>
                        <div className='button-container'>
                            <Button onClick={() => history.push('/management-agreement/' + data.management_id)} size="sm">Continue</Button>
                        </div>
                    </div>
                </div>
            </div>
    )
}