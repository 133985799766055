import { useState } from "react";

import { Button, Container, Dropdown, Nav, Navbar, NavDropdown, Offcanvas } from "react-bootstrap";
import { useHistory } from "react-router-dom";



export default function AdminMobileNav({ logout, admin2 }) {
    const [show, setShow] = useState(false);
    const history = useHistory()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleNavigate = (path) => {
        history.push(path)
        setShow(false)
    }

    return (
        <>
            <Navbar>
                <Container>
                    <Navbar.Brand href="#home"><img style={{ marginRight: "1em" }} src="https://i.imgur.com/px19yim.png" width="30px" height="30px" />Las Azucenas</Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar>
                            <Nav>
                                <Nav.Item>
                                    <img onClick={handleShow} src="/menu.svg" alt="menu" />
                                </Nav.Item>

                            </Nav>
                        </Navbar>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title><img style={{ marginRight: "1em" }} src="https://i.imgur.com/px19yim.png" width="30px" height="30px" />Las Azucenas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {!admin2 && <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <hr />
                        <Button onClick={() => handleNavigate('/owners')} variant="success" size="lg">
                            Owners
                        </Button>
                        <hr />
                        <hr />
                        <Button onClick={() => handleNavigate('/pricing')} variant="success" size="lg">
                            Pricing
                        </Button>
                        <hr />



                        <Button onClick={() => logout()} variant="outline-success" size="lg">
                            Logout
                        </Button>

                    </div>}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}