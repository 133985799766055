import { Button, Container, Form, FormControl, Card, ListGroup, Alert, Navbar, Spinner } from 'react-bootstrap'
import './Marketplace.scss'
import VendorCard from './vendorCard'
import marketData from './data'
import { Route, Switch } from 'react-router-dom'
import VendorPage from './vendorPage'
import Session from "supertokens-auth-react/recipe/session";
import axios from "axios";
import { useEffect, useState } from 'react'


export default function Marketplace({ userInfo }) {
    const [trees, setTrees] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getMarketPlaceTrees()
    }, [])
    const getMarketPlaceTrees = () => {
        axios.get(process.env.REACT_APP_API + '/user/marketplace/trees').then(res => {
            setTrees(res.data)
            setLoading(false)
        })
    }


    return (
        <div className="marketplace-dash-content">
            {loading ? <Spinner animation="border" variant="success" size="lg" />
                :

                <Switch>
                    <Route exact path="/marketplace/">
                        <Navbar bg="secondary" variant="dark" expand="lg">
                            <Container fluid>
                                <Navbar.Brand>Marketplace</Navbar.Brand>
                                <Navbar.Toggle aria-controls="navbarScroll" />
                                <Navbar.Collapse id="navbarScroll">
                                    {/* <Form className="d-flex">
                                        <FormControl
                                            type="Search for vendor"
                                            placeholder="Search"
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                        <Button variant="light">Search</Button>
                                    </Form> */}
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <hr />
                        {trees.length === 0 ?
                            <div>
                                <Alert variant="light">
                                    There are currently no trees listed by vendors for resale. However you can still purchase new trees from Las Azucenas.
                                    After Bill of Sales are completed your online account will update to reflect your new purchases.
                                    <hr />
                                </Alert>
                                <div className="vendor-list">
                                    <Card border="success" style={{ width: '300px' }}>
                                        <Card.Header ><img id="market-icon" src="/market.svg" width="30px" /> Purchase Trees Online</Card.Header>
                                        <Card.Body bg="secondary" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                            <ListGroup as="ol" >

                                                <ListGroup.Item
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                >
                                                    <div className="ms-2 me-auto">
                                                        {/* <div className="fw-bold" style={{ color: 'seagreen' }}>Las Azucenas Tropical Hardwood Trees</div> */}
                                                        {/* <hr/> */}
                                                        If you are purchasing more than 5 trees please get in contact with Las Azucenas to arrange a transfer through Zelle.

                                                        <hr />

                                                        <strong>$450</strong>/tree
                                                    </div>
                                                </ListGroup.Item>


                                            </ListGroup>
                                            <div style={{ minWidth: '100%' }}>
                                                <hr />
                                                <Button style={{ minWidth: '100%' }} variant="success" size="small" onClick={() => window.open('https://goodalways.com/purchase', '_blank')}>Purchase</Button>


                                            </div>
                                        </Card.Body>
                                    </Card>
                                    <Card border="success" style={{ width: '300px' }}>
                                        <Card.Header ><img id="market-icon" src="/market.svg" width="30px" /> Contact Las Azucenas</Card.Header>
                                        <Card.Body bg="secondary" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                            <ListGroup as="ol" >

                                                <ListGroup.Item
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                >
                                                    <div className="ms-2 me-auto">
                                                        {/* <div className="fw-bold" style={{ color: 'seagreen' }}>Las Azucenas Tropical Hardwood Trees</div> */}
                                                        {/* <hr/> */}
                                                        Get in contact with Las Azucenas to purchase trees from Las Azucenas through a Zelle Transfer.
                                                        <br />
                                                        <br />


                                                        <hr />

                                                        {/* <strong>$450</strong>/tree <br/> */}
                                                        <strong>alain@azucenas.org</strong>
                                                    </div>
                                                </ListGroup.Item>


                                            </ListGroup>
                                            <div style={{ minWidth: '100%' }}>

                                                {/* <hr /> */}

                                                {/* <Button style={{ minWidth: '100%' }} variant="success" size="small" onClick={() => window.open('https://goodalways.com/contact', '_blank')}>Contact</Button> */}


                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            :
                            <div>

                                <div className="vendor-list">
                                    {trees.map((item, index) => (

                                        <VendorCard key={index + 'card'} vendor={item.owner_id} you={item.owner_id === userInfo.owner_id ? true : false} list={item.trees} id={item.owner_id} />

                                    ))}


                                </div>
                                <hr />
                                <Alert variant="light">
                                    You can still purchase new trees from Las Azucenas.
                                    After Bill of Sales are completed your online account will update to reflect your new purchases.

                                </Alert>
                                <hr />
                                <div>
                                    <div className="vendor-list">
                                        <Card border="success" style={{ width: '300px' }}>
                                            <Card.Header ><img id="market-icon" src="/market.svg" width="30px" /> Purchase Trees Online</Card.Header>
                                            <Card.Body bg="secondary" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                                <ListGroup as="ol" >

                                                    <ListGroup.Item
                                                        as="li"
                                                        className="d-flex justify-content-between align-items-start"
                                                    >
                                                        <div className="ms-2 me-auto">
                                                            {/* <div className="fw-bold" style={{ color: 'seagreen' }}>Las Azucenas Tropical Hardwood Trees</div> */}
                                                            {/* <hr/> */}
                                                            If you are purchasing more than 5 trees please get in contact with Las Azucenas to arrange a transfer through Zelle.

                                                            <hr />

                                                            <strong>$450</strong>/tree
                                                        </div>
                                                    </ListGroup.Item>


                                                </ListGroup>
                                                <div style={{ minWidth: '100%' }}>
                                                    <hr />
                                                    <Button style={{ minWidth: '100%' }} variant="success" size="small" onClick={() => window.open('https://goodalways.com/purchase', '_blank')}>Purchase</Button>


                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <Card border="success" style={{ width: '300px' }}>
                                            <Card.Header ><img id="market-icon" src="/market.svg" width="30px" /> Contact Las Azucenas</Card.Header>
                                            <Card.Body bg="secondary" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                                <ListGroup as="ol" >

                                                    <ListGroup.Item
                                                        as="li"
                                                        className="d-flex justify-content-between align-items-start"
                                                    >
                                                        <div className="ms-2 me-auto">
                                                            {/* <div className="fw-bold" style={{ color: 'seagreen' }}>Las Azucenas Tropical Hardwood Trees</div> */}
                                                            {/* <hr/> */}
                                                            Get in contact with Las Azucenas to purchase trees through a Zelle Transfer.
                                                            <br />
                                                            <br />

                                                            <hr />

                                                            <strong>$450</strong>/tree
                                                        </div>
                                                    </ListGroup.Item>


                                                </ListGroup>
                                                <div style={{ minWidth: '100%' }}>
                                                    <hr />
                                                    {/* <Button style={{ minWidth: '100%' }} variant="success" size="small" onClick={() => window.open('https://goodalways.com/contact', '_blank')}>Contact</Button> */}


                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        }

                    </Route>
                    <Route path="/marketplace/:id">
                        <VendorPage treeList={trees} />
                    </Route>
                </Switch>
            }
        </div>
    )
}