import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import axios from "axios";
import './AdminNav.scss'
import { Alert, Badge, Button, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
Session.addAxiosInterceptors(axios);

export default function AdminNav({ logout, admin2 }) {
    const history = useHistory()
    useEffect(() => {
    }, [])

    return (
        <div className="Admin-Nav">
            <hr></hr>
            <img style={{ cursor: 'pointer' }} onClick={() => history.push('/')} src="/lasazucenas.png" width="90px" />

            <Alert variant="success">Admin View</Alert>
            <hr></hr>
            <div style={{ minWidth: '100%' }} className="d-grid gap-2">
                <ListGroup variant="flush">
                    {!admin2 && <>  <ListGroup.Item onClick={() => history.push('/owners')} action variant="light">
                        Tree Owners
                    </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/documents')} action variant="light">
                            Documents
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/onboarding')} action variant="light">
                            Onboarding
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/trees')} action variant="light">
                            Tree Search
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/pricing')} action variant="light">
                            Pricing
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/broker')} action variant="light">
                            Broker
                        </ListGroup.Item>
                        <ListGroup.Item onClick={() => history.push('/orders')} action variant="light">
                            Orders
                        </ListGroup.Item></>}

                    <ListGroup.Item onClick={() => logout()} action variant="primary" size="lg">
                        Logout
                    </ListGroup.Item>
                </ListGroup>

            </div>

        </div>
    )
}