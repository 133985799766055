import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Button, Row, Spinner, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import convertDoctype from "../../../utilities/convertDocType";
import formatTimestamp from "../../../utilities/formatTimestamp";


export default function ClientDocuments(props) {
    const [docs, setDocs] = useState([])
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        axios.get(process.env.REACT_APP_API + '/user/documents').then(res => {
            setDocs(res.data.documents)
            setLoading(false)
        })
    }
    const handleReturnStatus = (document) => {
        if (document.type === 'other') {
            return 'Completed'
        }
        if (document.client_sign) {
            if (document.admin_sign) {
                return 'Completed'
            } else {
                return 'Waiting for Las Azucenas to complete'
            }
        } else {
            return 'Ready for you to sign'
        }
    }
    const returnAction = (message, document) => {
        console.log(document)
        if (message === 'Completed') {
            if (document.type === 'other') {
                return <Button onClick={() => window.open(document.link, '_blank')}>Download</Button>
            }
            return <Button onClick={() => downloadDocument(document)}>Download</Button>
        }
        else if (message === 'Ready for you to sign') {
            if (document.type === 'mgmt') {
                return <Button variant="warning" href={'/' + 'management-agreement' + '/' + document.document_id} target="_blank">Sign Document</Button>
            } else {
                return <Button variant="warning" href={'/' + document.type + '/' + document.document_id} target="_blank">Sign Document</Button>
            }
        } else {
            return ''
        }
    }
    const downloadDocument = (doc) => {
        axios({
            url: process.env.REACT_APP_API + '/storage/pdf/' + doc.document_id, //your url
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${convertDoctype(doc.type, document)}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
    }

    return (
        <div className="client-dash-content">
            <h2>Documents Page</h2>
            <hr />
            {
                loading ? <Spinner animation="border" variant="success" ></Spinner> :
                    <>
                        <Table responsive>
                            <thead>
                                <th>Document Type</th>
                                <th>Date Created</th>
                                <th>Status</th>
                                <th></th>
                            </thead>
                            <tbody>{docs.map((item, index) => (
                                <tr>
                                    <td>{convertDoctype(item.type, item)}</td>
                                    <td>{formatTimestamp(item.date_created)}</td>
                                    <td>{handleReturnStatus(item)}</td>
                                    <td>{returnAction(handleReturnStatus(item), item)}</td>
                                </tr>
                            ))}
                                {props.userInfo.bill_of_sale.length > 20 ? <tr>
                                    <td>Original Purchase Document</td>
                                    <td>---</td>
                                    <td>Completed</td>
                                    <td><Button target="_blank" href={props.userInfo.bill_of_sale}>Download</Button></td>
                                </tr> : ''}</tbody>
                        </Table>
                    </>
            }
        </div>
    )
}